import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircle} from "@fortawesome/free-regular-svg-icons";
import {MAINTENANCE_CATEGORY_ID, MAINTENANCE_CATEGORY_LABEL, SKILLS} from "../../../helper/constants";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

class _Step extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value ?? [],
      showItems: {}
    }
    Object.keys(SKILLS).map(key =>
      this.state.showItems[key] = false)
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.array,
      job: PropTypes.array,
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'need-create-skills'
  }

  get isMaintenanceJob() {
    return this.props.job?.category?.id === MAINTENANCE_CATEGORY_ID
  }

  /**
   * Add or Remove value
   * @param value
   * @private
   */
  _update(value) {
    this.setState(prevState => {
      prevState.value.includes(value) ?
        prevState.value = prevState.value.filter(v => v !== value) :
        prevState.value.push(value)
      return prevState
    })
  }

  render() {
    return <div className={`${this.className}`}>
      <div className={`${this.className}__label`}>
        {this.props.t('needs.create.skill.label')}
      </div>
      <div className={`${this.className}__list`}>
        {Object.entries(SKILLS)
          .filter(([key]) => this.isMaintenanceJob || key !== MAINTENANCE_CATEGORY_LABEL)
          .map(([key, value], index) => <div className={`${this.className}__block`} key={index}>
            <button className={`${this.className}__block__title`}
              onClick={() => this.setState(prevState => {
                prevState.showItems[key] = !prevState.showItems[key]
                return prevState
              })}
              title={this.state.showItems[key] ? this.props.t('needs.create.skill.reduce') : this.props.t('needs.create.skill.expand')}>
              {this.props.t(`proposal.skill.label.${key}`)} :
              <FontAwesomeIcon className={`${this.className}__block__title__action`}
                icon={this.state.showItems[key] ? faChevronUp : faChevronDown}/>
            </button>
            {this.state.showItems[key] && (value.filter(value => this.isMaintenanceJob || value < 50).map(value => (
              <button className={`${this.className}__item`} key={value} onClick={() => this._update(value)}>
                <div className={`${this.className}__item__label`}>
                  {this.props.t(`needs.skill.${value}`)}
                </div>
                {this.state.value.includes(value) ? <FontAwesomeIcon icon={faCheckCircle}/> :
                  <FontAwesomeIcon icon={faCircle}/>}
              </button>)))}
          </div>)}
      </div>
      <div className={`${this.className}__actions`}>
        <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
          {this.props.t('action.previous')}
        </button>
        <button className={`${this.className}__action`} onClick={() => this.props.onContinue(this.state.value)}>
          {this.props.t('action.next')}
        </button>
      </div>
    </div>
  }
}

class _Label extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.array.isRequired
    }
  }

  render() {
    if(this.props.value.length === 0) {
      return <>{this.props.t('needs.skill.none')}</>
    }
    return <>{this.props.t('needs.skill.label')} : {this.props.value.map(v =>
      this.props.t(`needs.skill.${v}`).replace(new RegExp(this.props.t('needs.skill.label') + '[ ]*'), '')).join(' / ')}</>
  }
}

export default {
  Step: withTranslation()(_Step),
  Label: withTranslation()(_Label)
}
