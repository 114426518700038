import React from 'react'
import {withTranslation} from "react-i18next";
import withRouter from '../../lib/withRouter'
import PropTypes from "prop-types";
import moment from 'moment'
import 'moment/locale/fr'
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProposalApiClient from "../../service/proposal.api.client";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import NeedApiClient from "../../service/need.api.client";
import {Link} from "react-router-dom";

export class NeedTeaser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      need: props.need,
      skills: [],
      hasProfile : false,
      hasProposedProfile: false,
      proposals: []
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func, // withTranslation
      need: PropTypes.object.isRequired,
      scope: PropTypes.string.isRequired
    }
  }

  get className() {
    return 'need-teaser'
  }

  get profileAcceptedMessage() {
    return this.props.scope === 'mines' ? this.props.t('needs.hasAcceptedProfile') : this.props.t('needs.withProfileAccepted')
  }

  async componentDidMount() {
    if(!this.props.need) {
      return
    }
    try {
      let skills = []
      for (const value of Object.values(this.props.need.skills)) {
        if (value) {
          skills.push(this.props.t(`needs.skill.${value}`))
        }
      }
      const {profilesAvailable, hasProposedProfile} = this.props.scope !== 'mines' && await NeedApiClient.getNeedProfilesAvailable(this.props.need.id)
      const hasProfile = this.props.scope !== 'mines' && !!(profilesAvailable.length)
      const {proposals} = await ProposalApiClient.findBy({need: this.props.need.id})
      this.setState({skills, hasProfile, hasProposedProfile, proposals})

    } catch (e) {
      this.setState({error: this.props.t('needs.teaserError')})
    }
  }

  __blank() {
    return <div className={`${this.className} blank`}>
      <div className={`${this.className} blank__giid`}> </div>
      <div className={`${this.className} blank__resource`}> </div>
      <div className={`${this.className} blank__dates`}> </div>
      <div className={`${this.className} blank__skills`}> </div>
    </div>
  }

  render() {
    if(!this.state.need) {
      if(!this.props.need) {
        return null
      }
      return this.__blank()
    }
    if(this.state.error) {
      return <div className={`${this.className}__error`}>{this.state.error}</div>
    }
    return (
      <Link className={(this.props.scope === 'mines' && this.state.proposals.filter(p => p.isSelected).length > 0) ||
      (this.props.scope !== 'mines' && this.state.need.isSelected) ?
        `${this.className} with-selected-profile` : this.state.hasProposedProfile ? `${this.className} with-proposed-profile` :
          this.state.hasProfile ? `${this.className} with-profile` : this.className}
      to={`/requests${this.props.scope === 'mines' ? '' : 'Others'}/${this.props.need.id}`}>
        <div className={`${this.className}__info`}>
          <div className={`${this.className}__giid-title`}>{this.props.t('needs.create.giid.title')}{this.state.need.giid?.title}</div>
          <div className={this.state.hasProfile ? `${this.className}__title with-profile` : `${this.className}__title`}>
            <div>{this.state.need.resource}</div>
          </div>
          {this.state.hasProfile &&
            (
              <div className={this.state.hasProfile ? `${this.className}__title with-profile` : `${this.className}__title`}>
                <div className={`${this.className}__has-profile`}>
                  {(this.props.scope !== 'mines' && this.state.need.isSelected) || this.state.proposals.filter(p => p.isSelected).length > 0 ?
                    this.profileAcceptedMessage :
                    this.state.hasProposedProfile ? this.props.t("needs.hasProposedProfile") : this.props.t("needs.hasProfile")}
                </div>
                <div>{this.state.hasProfile && !this.state.hasProposedProfile &&
                  (<FontAwesomeIcon icon={faCheckCircle}/>)}</div>
              </div>

            )
          }
          {(this.props.scope === 'mines' && this.state.proposals.filter(p => p.isSelected).length > 0) &&
                <div className={`${this.className}__has-accepted-profile`}>
                  {this.props.t("needs.hasAcceptedProfile")}
                </div>
          }
          <div className={`${this.className}__date`}>
            {this.props.t("needs.date.from")} {moment(this.props.need?.startDate, 'X').format('LL')} {this.props.t("needs.date.to")} {moment(this.props.need?.endDate, 'X').format('LL')}
          </div>

          <div className={`${this.className}__skills`}>
            {!this.state.skills.length
              ? (<span>{this.props.t("needs.skill.without")}</span>)
              : (<span>{this.props.t("needs.skill.requested")}{this.state.skills.join(" / ")}</span>)}
          </div>
        </div>
        {this.props.scope === 'mines' && this.state.proposals.length > 0 && this.state.proposals.filter(p => p.isSelected).length === 0 &&
        <div className={`${this.className}__proposals`}>
          <FontAwesomeIcon icon={faBell}/>
          <span>{this.state.proposals?.length}</span>
        </div>
        }
      </Link>
    )
  }
}

export default withTranslation()(withRouter(NeedTeaser))
