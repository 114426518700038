import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import categoryApiClient from "../../../service/category.api.client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSpinner} from "@fortawesome/free-solid-svg-icons";
import {LEVEL} from "../../../helper/constants";
import Markdown from 'react-markdown'

class _Step extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      showRange: {}
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.array,
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'profile-create-job'
  }

  componentDidMount() {
    categoryApiClient.findAll().then(({categories}) => {
      const showRange = {}
      const value = this.props.value ?? []
      categories.map(category => {
        category.types.map(type => {
          type.level = this._getDefaultLevel(type)
        })
        showRange[category.label] = value.findIndex(v => v.categoryId === category.id) >= 0
      })

      this.setState({categories, showRange, loading: false})
    }).catch(e => {
      console.error(e)
      this.setState(prevState => {
        prevState.errors = prevState.errors || []
        prevState.errors.push(this.props.t('profile.create.job.errors.loading'))
        prevState.loading = false
        return prevState
      })
    })
  }

  _change(value, categoryIndex, typeIndex) {
    this.setState(prevState=> {
      prevState.categories[categoryIndex].types[typeIndex].level = parseInt(value)
      return prevState
    })
  }

  _cancel(categoryIndex, categoryLabel) {
    this.setState(prevState=> {
      prevState.categories[categoryIndex].types.map(type => type.level = 0)
      prevState.showRange[categoryLabel] = false
      return prevState
    })
  }

  _continue() {
    if(this.state.categories.map(c => c.types).flat().filter(t => t.level === 3).length !== 1) {
      this.setState(prevState => {
        prevState.errors = prevState.errors || []
        prevState.errors.push(this.props.t('profile.create.job.errors.form'))
        return prevState
      })
      return
    }
    this.props.onContinue(this.state.categories.map(c => {
      c.types.map(type => type.categoryId = c.id)
      return c.types
    }).flat().filter(t => t.level > 0))
  }

  _getDefaultLevel(type) {
    const value = this.props.value ?? []
    const val = value.find(v => v.id === type.id)
    return val ? val.level : type.level ?? 0
  }

  render() {
    const {categories, errors, loading} = this.state
    if (loading) {
      return (<div className={`${this.className}__loading`}>
        <FontAwesomeIcon icon={faSpinner}/>
      </div>)
    }

    return <div className={this.className}>
      <Markdown className={`${this.className}__label`}>
        {this.props.t('profile.create.job.label')}
      </Markdown>
      <div className={`${this.className}__categories`}>
        {categories.map((category, key) =>
          <div className={`${this.className}__category`} key={key}>
            <div className={`${this.className}__category__label`}>
              {category.label}
              {this.state.showRange[category.label] ?
                <button className={`${this.className}__action-cancel`}
                  onClick={() => this._cancel(key, category.label)}>{this.props.t('profile.action.cancel')}
                </button>
                :
                <button className={`${this.className}__action-add`}
                  onClick={() => this.setState({showRange: {[category.label]: true}}) }>{this.props.t('profile.action.add')}
                </button>
              }
            </div>
            {this.state.showRange[category.label] &&
            <div className={`${this.className}__level__label`}>
              {LEVEL.map(level =>
                <div key={level} className={`profile-create-job__level ${level}`}>{level}</div>
              )}
            </div>
            }
            <div className={`${this.className}__category__types`}>
              {category.types.map((type, i) => (
                <div className={`${this.className}__type`} key={i}>
                  <div className={`${this.className}__type__label`}>
                    {type.label}
                  </div>
                  {this.state.showRange[category.label] &&
                <input className={`${this.className}__level__range ${LEVEL[type.level]}`} title={LEVEL[type.level]}
                  type='range' value={type.level} min='0' max='3' step='1'
                  onChange={(event) => this._change(event.target.value, key, i)}/>
                  }
                </div>
              ))}
            </div>
          </div>)}
        {errors &&
        <div className={`${this.className}__errors`}>
          {errors.map((error, key) => (
            <div className={`${this.className}__error`} key={key}>
              {error}
            </div>)
          )}
        </div>
        }
        <div className={`${this.className}__actions`}>
          <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
            {this.props.t('action.previous')}
          </button>
          <button className={`${this.className}__action` + (this.state.categories.map(c => c.types).flat().filter(t => t.level === 3).length !== 1 ?
            ' disabled' : '')} onClick={() => this._continue()}>
            {this.props.t('action.next')}
          </button>
        </div>
      </div>
    </div>
  }
}

class _Label extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.array.isRequired
    }
  }

  render() {
    return <>{this.props.value.length === 1 ? this.props.value.map(v=> v.label) :
      this.props.value.map(v=> v.label).join(' / ')}
    </>
  }
}

export default {
  Step: withTranslation()(_Step),
  Label: withTranslation()(_Label)
}
