class CategoryApiClient {

  /**
   * Find categories
   * @return {Promise<any>}
   */
  async findAll() {
    const response = await fetch(window.location.origin + '/api/categories')

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

}

const categoryApiClient = new CategoryApiClient()
export default categoryApiClient
