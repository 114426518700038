import AuthApiClient from "../service/auth.api.client";

class Session {
  /**
     * Get current user, saved in localStorage
     * On force : (return Promise) check if the user is logged and get the user in API
     * @param {boolean=false} force
     * @returns {object|null|Promise<object|null>}
     */
  currentUser(force = false) {
    try {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user) {
        return user
      }
    } catch (e) {
      /* do nothing */
    }
    if (!force) {
      return null
    }
    const jwt = document.cookie.replace(
      /(?:^|.*;\s*)x-access-token\s*=\s*([^;]*).*$|^.*$/, '$1')
    if (!jwt) {
      return null
    }
    return AuthApiClient.getUser().then(({user}) => {
      localStorage.setItem('user', JSON.stringify(user))
      return user
    })
  }

  /**
     * Get or set if the app ask to the user notificationPermission
     */
  askNotificationPermission(asked) {
    if (asked === undefined) { // GET
      return !!localStorage.getItem('askNotificationPermission')
    }
    if (asked) {
      localStorage.setItem('askNotificationPermission', '1')
    } else {
      localStorage.removeItem('askNotificationPermission')
    }
  }

  /**
     * Redirect to the platform authentication page
     */
  login() {
    window.location = `https://${process.env.REACT_APP_PLATFORM}/connect/sso?pwa="1"`
  }

  /**
     * Remove localStorage, cookie & logout on API
     * @returns {Promise<void>}
     */
  async logout() {
    localStorage.removeItem('user')
    document.cookie = 'x-access-token=; Path=/; domain=.idshare.fr; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    await AuthApiClient.logout()
  }
}

export default new Session()
