import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

class AddToHomeScreen extends Component {
  static get propTypes() {
    return {
      t: PropTypes.func // withTranslation
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      closed: false
    }
  }

  get className() {
    return 'addToHomeScreen'
  }

  componentDidMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.setState({deferredPrompt: e})
    });
  }

  _isInStandaloneMode() {
    return 'standalone' in window.navigator && window.navigator.standalone
  }

  _install = () => {
    this.state.deferredPrompt.prompt();
    this.state.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        this.setState({deferredPrompt: undefined, closed: true})
      }
    })
  }

  _close() {
    this.setState({closed: true})
  }

  render() {
    const {deferredPrompt, closed} = this.state
    if (!deferredPrompt || this._isInStandaloneMode() || closed) {
      return null
    }

    return (
      <div className={this.className}>
        <img src={'/icon-96x96.png'}/>
        <button onClick={() => this._install()}>
          {this.props.t('addToHomeScreen.install')}
        </button>
        <FontAwesomeIcon icon={faTimes} title={this.props.t('action.close')} onClick={() => this._close()}/>
      </div>
    )
  }
}

export default withTranslation()(AddToHomeScreen);
