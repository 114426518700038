import React from 'react'
import {Routes, BrowserRouter, Route} from 'react-router-dom'
import {I18nextProvider} from 'react-i18next'
import ReactDOM from 'react-dom'
import Home from './component/home'
import './style/style.scss'
import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import Login from './component/block/login'
import NavBar from "./component/block/navbar";
import NeedsList from "./component/needs/needs.list";
import session from './lib/session';
import AskNotificationPermission from './component/block/askNotificationPermission'
import AddToHomeScreen from "./component/block/addToHomeScreen";
import Request from "./component/request/request";
import RequestOther from "./component/request/requestOther";
import AppContext from './context/app.context'
import NeedsCreate from './component/needs/create';
import ProfileCreate from './component/profile/create';
import ProposalList from './component/proposal/proposal.list'
import OffersList from "./component/offers/offer.list";
import DeclinedProposals from "./component/proposal/declined";
import GiidList from "./component/giid/list";

// register DatePicker locale
import {registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
import authApiClient from "./service/auth.api.client";


registerLocale('fr', fr);

i18n.use(HttpApi).use(initReactI18next).init({
  lng: 'fr',
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  },
  debug: process.env.NODE_ENV !== 'production',
  react: {
    useSuspense: false
  }
})

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      askedNotificationPermission: false
    }
  }

  async componentDidMount() {
    await navigator.serviceWorker.register(window.location.origin + '/sw.js', {scope: '.'})
    try {
      const {user} = await authApiClient.getUser()
      this.setState({user: user})
    } catch (e) {
      console.error(e)
      await this._logout()
    }
  }


  _changeUser(user) {
    this.setState({user})
  }

  async _logout() {
    await session.logout()
    this.setState({user: null})
  }


  render() {
    const {askedNotificationPermission, user} = this.state
    if (!user) {
      return (
        <div className="app">
          <AddToHomeScreen/>
          <div className="app__screen">
            <Login onChange={user => this._changeUser(user)}/>
          </div>
        </div>
      )
    }
    if (!askedNotificationPermission) {
      return (
        <AppContext.Provider value={{user}}>
          <div className="app">
            <div className="app__screen">
              <AskNotificationPermission
                onContinue={() => this.setState({askedNotificationPermission: true})}/>
            </div>
            <AddToHomeScreen/>
          </div>
        </AppContext.Provider>
      )
    }
    return (
      <AppContext.Provider value={{
        user,
        logout: () => this._logout()
      }}>
        <BrowserRouter>
          <div className="app">
            <div className="app__screen">
              <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/giid/mines"
                  element={<GiidList scope='mines'/>}
                />
                <Route path="/giid/others"
                  element={<GiidList scope='others'/>}
                />
                <Route
                  path="/needs/mines/:id"
                  element={<NeedsList scope='mines'/>}
                />
                <Route
                  path="/needs/others/:id"
                  element={<NeedsList scope='others'/>}
                />
                <Route path="/needs/create/:id"
                  element={<NeedsCreate/>}
                />
                <Route path="/needs/create/:id/*"
                  element={<NeedsCreate/>}
                />

                <Route path="/:id/profile/create"
                  element={<ProfileCreate/>}
                />
                <Route path="/:id/profile/create/*"
                  element={<ProfileCreate/>}
                />

                <Route
                  path="/requests/:id"
                  element={<Request/>}
                />
                <Route
                  path="/requestsOthers/:id"
                  element={<RequestOther/>}
                />
                <Route
                  path="/offers/list/:id"
                  element={<OffersList/>}
                />
                <Route
                  path="/needs/makeProposal/:id"
                  element={<ProposalList/>}
                />
                <Route
                  path="/declined/:id"
                  element={<DeclinedProposals/>}
                />
              </Routes>
            </div>
            <NavBar onLogout={() => this._logout()}/>
            <AddToHomeScreen/>
          </div>
        </BrowserRouter>
      </AppContext.Provider>)
  }
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App/>
  </I18nextProvider>,
  document.getElementById('root')
)
