class AppClient {

  /**
     * Reset
     * @return {Promise<boolean>}
     */
  async reset() {
    const response = await fetch(`${window.location.origin}/api/application/reset`)

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }
}
const appClient = new AppClient()
export default appClient
