import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import GiidApiClient from "../../../service/giid.api.client";
import AppContext from "../../../context/app.context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {
  CLIENTS, CLIENTS_LIST_LIMIT,
  GIID_STATUS_NOT_PUBLIC,
  GIID_STATUS_NOT_SAVED,
  GIID_STATUS_OPEN, VISIBILITY_NATIONAL,
  VISIBILITY_REGION,
  VISIBILITY_REGION_NEIGHBOUR
} from "../../../helper/constants";
import moment from "moment";
import FormAutocomplete from "../../../helper/form-autocomplete";

class _Step extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props)
    const start = props.period ? props.period[0] : Date.now()
    this.state = {
      loading: true,
      page: 0,
      value: {
        visibility : 1,
        title : `${moment(start).format('DD MMMM YYYY') ?? ''} ${JSON.parse(localStorage.getItem('user')).group.company.name ?? ''} ${Object.values(this.reason).slice(-1)}`,
        client: props.value?.client
      },
      clients: CLIENTS.map(client => { return { label: client }}).filter((_, index) => index < CLIENTS_LIST_LIMIT)
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      period: PropTypes.array.isRequired,
      value: PropTypes.object,
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'need-create-giid'
  }

  get reason() {
    return this.props.t('needs.create.reason.options', {returnObjects: true})
  }

  componentDidMount() {
    this._load()
  }

  _load() {
    let [startDate, endDate] = this.props.period || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    GiidApiClient.findBy({
      page: this.state.page + 1,
      author: this.context.user.group?.company?.id,
      status: [GIID_STATUS_OPEN, GIID_STATUS_NOT_SAVED, GIID_STATUS_NOT_PUBLIC],
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    })
      .then(({giids, hasNextPage, page}) => this.setState({giids, loading: false, page, hasNextPage}))
      .catch(e => {
        console.error(e)
        if (e.status === 401) {
          this.context.logout()
          return
        }
        this.setState(prevState => {
          prevState.errors = prevState.errors || []
          prevState.errors.push(this.props.t('needs.create.giid.errors.loading'))
          prevState.loading = false
          return prevState
        })
      })
  }

  _select(giid) {
    this.props.onContinue(giid)
  }

  _change(key, value) {
    this.setState(prevState => {
      if(key === 'reason') {
        prevState.value.reason = value
        const start = this.props.period ? this.props.period[0] : Date.now()
        prevState.value.title = `${moment(start).format('DD MMMM YYYY') ?? ''} ${JSON.parse(localStorage.getItem('user')).group.company.name ?? ''} ${this.reason[value]}`
      }
      if(key === 'client') {
        prevState.searchText = ''
      }
      key === 'visibility' ? prevState.value[key] = parseInt(value) : prevState.value[key] = value
      return prevState
    })
  }

  _continue() {
    if (!this.state.value.client) {
      this.setState(prevState => {
        prevState.errors = prevState.errors || []
        prevState.errors.push(this.props.t('needs.create.giid.errors.client'))
        prevState.loading = false
        return prevState
      })
      return
    }
    this.props.onContinue(this.state.value)
  }

  render() {
    const {
      errors,
      giids,
      loading
    } = this.state

    if (loading) {
      return (<div className={`${this.className}__loading`}>
        <FontAwesomeIcon icon={faSpinner}/>
      </div>)
    }

    return <div className={this.className}>
      {errors && (
        <div className={`${this.className}__errors`}>
          {errors.map((error, key) => (
            <div className={`${this.className}__error`} key={key}>
              {error}
            </div>)
          )}
        </div>
      )}

      <div className={`${this.className}__label`}>
        {this.props.t('needs.create.giid.label')}
      </div>
      <div className={`${this.className}__reason`}>
        <div className={`${this.className}__reason__label`}>
          {this.props.t('needs.create.reason.label')}
        </div>
        <select defaultValue={Object.keys(this.reason).slice(-1)} name="reason"
          onChange={(e) => this._change('reason', e.target.value)}>
          {Object.entries(this.reason).map(([value, label], key)=>
            <option key={key} value={value}>{label}</option>
          )}
        </select>
      </div>

      <div className={`${this.className}__title`}>
        <div className={`${this.className}__title__label`}>
          {this.props.t('needs.create.giid.title')}
        </div>
        <input type="text" value={this.state.value.title} readOnly/>
      </div>

      {this.__clients()}

      <div className={`${this.className}__visibility`}>
        <div className={`${this.className}__visibility__label`}>
          {this.props.t('needs.create.visibility.label')}
        </div>
        <select name="visibility" id="visibility" defaultValue={VISIBILITY_REGION}
          onChange={(e) => this._change('visibility', e.target.value)}>
          <option value={VISIBILITY_REGION}>{this.props.t('needs.create.visibility.options.region')}</option>
          <option value={VISIBILITY_REGION_NEIGHBOUR}>{this.props.t('needs.create.visibility.options.neighbour')}</option>
          <option value={VISIBILITY_NATIONAL}>{this.props.t('needs.create.visibility.options.national')}</option>
        </select>
      </div>

      <div className={`${this.className}__actions`}>
        <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
          {this.props.t('action.previous')}
        </button>
        <button className={`${this.className}__action`}
          onClick={() => this._continue()}>
          {this.props.t('action.next')}
        </button>
      </div>

      {giids?.length > 0 &&
      <div className={`${this.className}__existed`}>
        {this.props.t('needs.create.giid.existed')}
      </div>
      }
      <div className={`${this.className}__list`}>
        {giids?.length > 0 && giids.map((giid, key) => (
          <button className={`${this.className}__item`} key={key} onClick={() => this._select(giid)}>
            <div className={`${this.className}__item__title`}>
              {giid.title}
            </div>
            <div className={`${this.className}__item__client`}>
              {giid.client}
            </div>
          </button>
        ))}
        {this.state.hasNextPage && (
          <div className={`${this.className}__nextPage`} onClick={() => this._load()}>
            {this.props.t('needs.create.giid.nextPage')}
          </div>
        )}
      </div>
    </div>
  }

  /**
   * find matching client in clients list
   * @param {string} searchText
   * @private
   */
  _findClientsBy(searchText) {
    this.setState(prevState => {
      prevState.searchText = searchText
      prevState.clients = CLIENTS.filter(client =>
        client.toLowerCase().includes(searchText.toLowerCase().trim()))
        .filter((_, index) => index < CLIENTS_LIST_LIMIT).map(client => {return {label: client}})
      return prevState
    })
  }

  __clients() {
    return <div className={`${this.className}__client`}>
      <div className={`${this.className}__client__label`}>
        {this.props.t('needs.create.giid.client.label')}
      </div>
      <FormAutocomplete
        className={`${this.className}__client__list`}
        placeholder={this.props.t('needs.create.giid.client.placeholder')}
        options={this.state.searchText?.trim()?.length > 0 && (this.state.clients.length > 0 ? this.state.clients :
          [{label: this.props.t('needs.create.giid.client.empty')}])}
        onChangeSearch={searchText => this._findClientsBy(searchText)}
        value={this.state.value.client}
        onChange={client => this._change('client', client?.label)}
        searchValue={this.state.searchText}
      />
    </div>
  }
}

class _Label extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.object.isRequired
    }
  }

  render() {
    return <>{this.props.value.title}</>
  }
}

export default {
  Step: withTranslation()(_Step),
  Label: withTranslation()(_Label)
}
