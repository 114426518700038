class AuthApiClient {

  /**
   * Logout
   * @return {Promise<boolean>}
   */
  async logout() {
    await fetch(window.location.origin + '/logout')
    return true

  }

  /**
   * Get the current user
   * @return {Promise<any>}
   */
  async getUser() {
    const response = await fetch(window.location.origin + '/api_user/user/current')
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }
}
const authApiClient = new AuthApiClient()
export default authApiClient
