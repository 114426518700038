export const SKILLS = {
  caces: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 31],
  meca: [12, 13, 14, 15, 16],
  wms: [17, 18, 19, 20, 28, 70, 71],
  domain: [21, 22, 23, 24, 25, 26, 27],
  others: [29, 30, 33, 80, 81],
  maintenances: [51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61]
}

export const [
  MOBILITY_REGION,
  MOBILITY_REGION_NEIGHBOUR,
  MOBILITY_NATIONAL, MOBILITY_INTERNATIONAL] = [1, 2, 3, 4]

export const VALUES = ['Retail sec', 'Retail frais', 'Retail gros volume', 'Retail textile', 'E-commerce', 'Industriel', 'GEM']
export const LEVEL = ['Non', 'Débutant', 'Polyvalent', 'Principal']

export const NATIONAL_ADMIN = 3
export const NO_PROFILE_ASWER = 3
export const TEST = 'test'
export const DEMO = 'demo'
export const PREPROD = 'preprod'

export const [VISIBILITY_REGION,
  VISIBILITY_REGION_NEIGHBOUR,
  VISIBILITY_NATIONAL, VISIBILITY_INTERNATIONAL] = [1, 2, 3, 4]

export const [GIID_STATUS_OPEN,
  GIID_STATUS_CANCELED,
  GIID_STATUS_NOT_SAVED,
  GIID_STATUS_NOT_PUBLIC,
  GIID_STATUS_CLOSED] = [0, 2, 3, 4, 5]

export const CLIENTS = ['Descours & Cabaud', 'LE COQ SPORTIF', 'PUMA', 'NESTLE PURINA', 'NOVEAL', 'FAIVELEY',
  'P&amp;G', 'Carrefour', 'LVMH', 'Givenchy', 'PANZANI', 'CARREFOUR / Multi Clients', 'Nespresso',
  'Auchan Textile', 'Amazon', 'Simply Market', 'Castorama', 'DSC / CEDEO', 'Leclerc', 'COURIR', 'Mano Mano',
  'Européenne d\'Embouteillage', 'AUCHAN.FR', 'AUCHAN DIRECT', 'Lagardère', 'Hachette', 'DSC / Point P',
  'Carrefour / Amazon', 'Bouygues Telecom', 'Lancel', 'SOGEFI - FILTRAUTO', 'ITM - Le Groupement des Mousquetaires',
  'Guerlain', 'Auchan', 'PPG', 'LIDL', 'Heineken', 'Boulanger', 'IPC', 'Leroy Merlin', 'WELDOM', 'CWF',
  'Pepsico', 'Copacking', 'Cora', 'Bricoman', 'Cellule transport', 'Norauto', 'ITM', 'FUCHS', 'ITM - Epicerie',
  'ITM - Parfumerie', 'Système U', 'GO Sport', 'PIERRE FABRE', 'SCREWFIX', 'Plateforme', 'DSC / STAC', 'Signify',
  'Naos', 'Bosch', 'L\'Occitane', 'Mapa', 'Yves Rocher', 'CDISCOUNT', 'Intermarché', 'Roquette',
  'Nespresso Dolce Gusto', 'Siège', 'INGENICO', 'MERIAL', 'HILTI', 'Sagemcom', 'Autre'
].sort((a, b) => a > b)

export const CLIENTS_LIST_LIMIT = 5
export const PHONE_PATTERN = /(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
export const MAINTENANCE_CATEGORY_ID = 61
export const MAINTENANCE_CATEGORY_LABEL = 'maintenances'
