import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import withRouter from '../../lib/withRouter'
import Footer from './footer'
import {Link} from "react-router-dom";

export class NavBar extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func, // withTranslation
      onLogout : PropTypes.func
    }
  }

  get className() {
    return 'navbar'
  }

  async _logout() {
    await this.props.onLogout()
  }

  render() {
    return (
      <>
        <div className={`${this.className}`}>
          <Link className={`${this.className}__home`} to='/'>
            <div className={`${this.className}__icon`}>
              <FontAwesomeIcon icon={faHome}/>
            </div>
            <div>
              {this.props.t('navbar.home')}
            </div>
          </Link>

          <Link className={`${this.className}__mine`} to='/giid/mines'>
            <div className={`${this.className}__icon`}>
              <img src={'/assets/images/IDSHARE-APP-MOBILE-ICONE-DEMANDER.svg'}/>
            </div>
            <div>
              {this.props.t('navbar.mine')}
            </div>
          </Link>

          <Link className={`${this.className}__others`} to='/giid/others'>
            <div className={`${this.className}__icon`}>
              <img src={'/assets/images/IDSHARE-APP-MOBILE-ICONE-PROPOSER.svg'}/>
            </div>
            <div>
              {this.props.t('navbar.others')}
            </div>
          </Link>

          <div className={`${this.className}__exit`} onClick={() => this._logout()}>
            <div className={`${this.className}__icon`}>
              <FontAwesomeIcon icon={faSignOutAlt}/>
            </div>
            <div>
              {this.props.t('navbar.exit')}
            </div>
          </div>
        </div>
        <Footer/>
      </>
    )
  }
}

export default withTranslation()(withRouter(NavBar))


