import {GIID_STATUS_NOT_PUBLIC, GIID_STATUS_NOT_SAVED, GIID_STATUS_OPEN} from "../helper/constants";

class GiidApiClient {

  /**
   * find my giids
   * @param {int} userId
   * @param {int=} page
   * @param {object} filter
   * @return {Promise<any>}
   */
  async findMines(userId, page, filter) {
    const params = {
      author: userId,
      status : [GIID_STATUS_OPEN, GIID_STATUS_NOT_SAVED, GIID_STATUS_NOT_PUBLIC],
      ignoreDate: true,
      ...filter
    }
    return await this.findBy(params, page || 1)
  }

  /**
   * find other giids
   * @param {int} userId
   * @param {int=} page
   * @param {object} filter
   * @return {Promise<any>}
   */
  async findOthers(userId, page, filter) {
    const params = {
      author: "^" + userId,
      status : GIID_STATUS_OPEN,
      ignoreDate: true,
      ...filter
    }
    return await this.findBy(params, page || 1)
  }

  /**
   * find giids by params
   * @param {object} params
   * @param {int} page
   * @return {Promise<any>}
   */
  async findBy(params, page) {
    const url = new URL(window.location.origin + '/api/giids')
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value)
    }

    if(page){
      url.searchParams.set('page', page)
    }
    const response = await fetch(url.toString())

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * find a giid by its id
   * @param {int} id
   * @return {Promise<any>}
   */
  async findById(id) {
    const url = new URL(window.location.origin + '/api/giids/' + id)
    const response = await fetch(url.toString())

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * close a giid
   * @param {int} id
   * @return {Promise<any>}
   */
  async close(id) {
    const response = await fetch(window.location.origin + '/api/giids/close/' + id,{
      headers: {
        'content-type': 'application/json'
      },
      method: 'PUT'
    })

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * count giids by organisation
   * @param {int} organisationId
   * @return {Promise<any>}
   */
  async count(organisationId) {
    const response = await fetch(window.location.origin + '/api/giids/count/' + organisationId,{
      headers: {
        'content-type': 'application/json'
      }
    })
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }
}

const giidApiClient = new GiidApiClient()
export default giidApiClient

