import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Route, Routes, Navigate, Link} from "react-router-dom";
import ProfileCreateDescription from "./description";
import ProfileCreateJob from "./job";
import ProfileCreateSkills from "./skills";
import withRouter from '../../../lib/withRouter'
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProfileApiClient from "../../../service/profile.api.client";
import { SKILLS } from "../../../helper/constants";
import NeedApiClient from "../../../service/need.api.client";
import IconPenToSquare from "../../../helper/penToSquare.icon";

export class ProfileCreate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      params: PropTypes.object.isRequired, // withRouter
      location: PropTypes.object.isRequired,// withRouter
      navigate: PropTypes.func // withRouter
    }
  }

  get className() {
    return 'profile-create'
  }

  get steps() {
    return {
      description: ProfileCreateDescription,
      job: ProfileCreateJob,
      skills: ProfileCreateSkills
    }
  }

  async componentDidMount() {
    await this._dispatch()
  }

  async _change(key, value) {
    await this.setState({[key]: value})
    await this._dispatch()
  }

  _back(key) {
    const index = Object.keys(this.steps).findIndex(step => step === key)
    if(index > 0) {
      this.props.navigate(Object.keys(this.steps)[index -1])
      return
    }
    this.props.navigate(`/requestsOthers/${this.props.params.id}`)
  }

  async _dispatch() {
    for (const key of Object.keys(this.steps)) {
      if (this.state[key] === undefined) {
        this.props.navigate(key)
        return
      }
    }
    await this._save()
  }

  async _save() {
    const {need} = await NeedApiClient.findOne(this.props.params.id)
    this.setState({loading: true})
    const params = {}
    for (const key of Object.keys(this.steps)) {
      params[key] = this.state[key]
    }

    ProfileApiClient.create({
      label: params.description.name,
      idContact: params.description.userId,
      radius: params.description.mobility -1,
      skills: Object.values(SKILLS).flat().sort((a, b) => a - b)
        .filter(skill => skill < 50).map(skill => params.skills.includes(skill) ? 1 : 0),
      description: params.job.length === 1 ? params.job[0].label :
        params.job.map(job => job.label).join(' + ').slice(0, params.job.map(job => job.label).join(' + ').lastIndexOf(' + ')),
      idCategory: params.job.find(job => job.level === 3).categoryId,
      idType: params.job.find(job => job.level === 3).id,
      idDomain: 10,
      idNeed: need?.id,
      job: params.job,
      licences: need?.skills?.map(skill => this.props.t(`proposal.skill.${skill}`)),
      maintenances: params.skills.filter(skill => skill >= 50)
        .map(skill => skill - 50).sort((a, b)=> a < b).join('/')
    }).then(() =>
      this.setState({
        loading: false,
        isCreated: true
      })
    ).catch(e => {
      console.error(e)
      this.setState(prevState => {
        prevState.errors = prevState.errors || []
        prevState.errors.push(this.props.t('profile.create.submittingError'))
        prevState.loading = false
        return prevState
      })
    })
  }

  render() {
    const hash = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length -1]
    if(!Object.keys(this.steps).includes(hash)) {
      return <Navigate to={Object.keys(this.steps)[0]}/>
    }

    if (this.state.loading) {
      return (<div className={`${this.className}__loading`}>
        <FontAwesomeIcon icon={faSpinner}/>
      </div>)
    }
    if (this.state.errors) {
      return <div className={this.className}>
        <div className={`${this.className}__errors`}>
          {this.state.errors.map((error, key) => (
            <div className={`${this.className}__error`} key={key}>
              {error}
            </div>)
          )}
        </div>
      </div>
    }

    if(this.state.isCreated) {
      return <div className={`${this.className} finished`}>
        <div className={`${this.className}__isCreated`}>{this.props.t('profile.isCreated')} </div>
        <Link className={`${this.className}__home`} to='/giid/others'>
          {this.props.t('action.home')}
        </Link>
      </div>
    }

    return (
      <div className={`${this.className}`}>
        <div className={`${this.className}__title`}>
          {this.props.t('needs.create.title', {currentStep: Object.keys(this.steps).findIndex(step => step === hash) + 1,
            totalStep: Object.keys(this.steps).length ?? 1})}
        </div>
        {this.state.isCreated &&
        <div className={`${this.className}__isCreated`}> {this.props.t('profile.isCreated')} </div>
        }
        <div className={`${this.className}__summary`}>
          {Object.entries(this.steps).map(([key, value]) =>
            this.state[key] && value.Label && (
              <div className={`${this.className}__summary__${key}`} key={key}>
                <div className={`${this.className}__summary__value`}>
                  <value.Label value={this.state[key]}/>
                </div>
                <button className={`${this.className}__summary__edit`} onClick={()=> this.props.navigate(key)}>
                  <IconPenToSquare/>
                </button>
              </div>
            ))}
        </div>
        <div className={`${this.className}__step`}>
          <Routes>
            {Object.entries(this.steps).map(([key, value]) => (
              <Route
                key={key}
                path={key}
                element={<value.Step
                  value={this.state[key]}
                  job={this.state.job}
                  onContinue={v => this._change(key, v)}
                  onBack={() => this._back(key)}/>}
              />
            ))}
          </Routes>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(ProfileCreate))
