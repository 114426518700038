import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {
  MOBILITY_INTERNATIONAL,
  MOBILITY_NATIONAL,
  MOBILITY_REGION, MOBILITY_REGION_NEIGHBOUR
} from "../../../helper/constants";

class _Step extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      value: {
        name: props.value?.name ?? '',
        mobility : props.value?.mobility ?? 1,
        userId: JSON.parse(localStorage.getItem('user')).userId
      }
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.object,
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'profile-create-description'
  }

  _change(key, value) {
    this.setState(prevState => {
      key !== 'name' ? prevState.value[key] = parseInt(value) : prevState.value[key] = value
      return prevState
    })
  }

  _continue() {
    if (!this.state.value.name) {
      this.setState(prevState => {
        prevState.error = this.props.t('profile.create.description.error')
        return prevState
      })
      return
    }
    this.props.onContinue(this.state.value)
  }

  render() {
    const {name, mobility} = this.state.value
    return <div className={this.className}>
      {this.state.error &&
        <div className={`${this.className}__error`}>{this.state.error}</div>
      }
      <div className={`${this.className}__label`}>
        {this.props.t('profile.create.description.label')}
      </div>
      <div className={`${this.className}__name`}>
        <div className={`${this.className}__name__label`}>
          {this.props.t('profile.create.description.name')}
        </div>
        <input
          value={name}
          placeholder={this.props.t('profile.create.description.placeholder')}
          onChange={(e) => this._change('name', e.target.value)}
        />
      </div>
      <div className={`${this.className}__mobility`}>
        <div className={`${this.className}__mobility__label`}>
          {this.props.t('profile.create.description.mobility.title')}
        </div>
        <select
          onChange={(e) => this._change('mobility', e.target.value)}>
          <option value={MOBILITY_REGION} selected={MOBILITY_REGION === mobility}>
            {this.props.t('profile.create.description.mobility.region')}</option>
          <option value={MOBILITY_REGION_NEIGHBOUR} selected={MOBILITY_REGION_NEIGHBOUR === mobility}>
            {this.props.t('profile.create.description.mobility.regionNeighbour')}</option>
          <option value={MOBILITY_NATIONAL} selected={MOBILITY_NATIONAL === mobility}>
            {this.props.t('profile.create.description.mobility.national')}</option>
          <option value={MOBILITY_INTERNATIONAL} selected={MOBILITY_INTERNATIONAL === mobility}>
            {this.props.t('profile.create.description.mobility.international')}</option>
        </select>
      </div>
      <div className={`${this.className}__contact`}>
        <div className={`${this.className}__contact__label`}>
          {this.props.t('profile.create.description.contact')}
        </div>
        <select>
          <option>{JSON.parse(localStorage.getItem('user')).identity.usualName}</option>
        </select>
      </div>

      <div className={`${this.className}__actions`}>
        <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
          {this.props.t('action.return')}
        </button>
        <button className={`${this.className}__action`} onClick={() => this._continue()}>
          {this.props.t('action.next')}
        </button>
      </div>
    </div>
  }
}

class _Label extends React.Component {
  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.object.isRequired
    }
  }

  render() {
    return <>{this.props.value.name}</>
  }
}

export default {
  Step: withTranslation()(_Step),
  Label: withTranslation()(_Label)
}
