import React from 'react'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import moment from 'moment'

class _Step extends React.Component {

  constructor(props) {
    super(props);
    const [startDate, endDate] = props.value ?? []
    this.state = {
      startDate: startDate,
      endDate: endDate
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.array,
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'need-create-dates'
  }

  _continue() {
    if(!this.state.startDate || !this.state.endDate) {
      this.setState({ errors: { startDate: !this.state.startDate, endDate: !this.state.endDate }})
      return
    }
    this.props.value ? this.props.onContinue([this.state.startDate, this.state.endDate]) :
      this.props.onContinue([moment(this.state.startDate).add(12, 'hours').toDate(),
        moment(this.state.endDate).add(12, 'hours').toDate()])
  }

  render() {
    return <div className={this.className}>
      <div className={`${this.className}__label`}>
        {this.props.t('needs.create.dates.label')}
      </div>
      <div className={`${this.className}__datePicker`}>
        <DatePicker
          selected={this.state.startDate}
          onChange={([startDate, endDate]) => this.setState({startDate, endDate})}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          selectsRange
          locale='fr'
          disabledKeyboardNavigation
          minDate={moment().add(1, 'days').toDate()}
          inline
        />
      </div>
      {this.state.errors?.startDate &&
      <div className={`${this.className}__error`}>{this.props.t('needs.create.errors.startDateRequired')}</div>
      }
      {this.state.errors?.endDate &&
      <div className={`${this.className}__error`}>{this.props.t('needs.create.errors.endDateRequired')}</div>
      }
      <div className={`${this.className}__actions`}>
        <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
          {this.props.t('action.previous')}
        </button>
        <button className={`${this.className}__action` + (!this.state.startDate || !this.state.endDate ?
          ' disabled' : '')} onClick={() => this._continue()}>
          {this.props.t('action.next')}
        </button>
      </div>
    </div>
  }
}

class _Label extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.array.isRequired
    }
  }

  render() {
    const [startDate, endDate] = this.props.value
    return <>{this.props.t('needs.create.summary.dates', {
      startDate: moment(startDate).format('DD-MM-YYYY'),
      endDate: moment(endDate).format('DD-MM-YYYY')
    })}</>
  }
}


export default {
  Step: withTranslation()(_Step),
  Label: withTranslation()(_Label)
}
