import React from 'react'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faUser} from "@fortawesome/free-solid-svg-icons";
import ProfileApiClient from "../../service/profile.api.client";

export class ProfileDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      groupedSkills : {},
      noSkillsFound: false,
      previousProfileId: 0
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func,
      profile : PropTypes.object,
      need: PropTypes.object,
      onGoBack : PropTypes.func
    }
  }

  async componentDidMount() {
    try{
      const groupedSkills = this._skillsClassifier(this.props.profile?.skills)
      const previous = await ProfileApiClient.getPreviousProfile({idTaskForce : this.props.need.id})
      this.setState({groupedSkills, previousProfileId: previous.previousProfile})
    }
    catch (e) {
      this.setState({noSkillsFound: true})
    }
  }

  get className() {
    return 'profile-details'
  }

  /**
   * classify proposal's skills
   * @param skills
   * @returns {{}}
   * @private
   */
  _skillsClassifier(skills) {
    const s = {}
    skills.map(skill => {
      if(skill < 12 || skill === 31) {
        s.caces ? s.caces.push(this.props.t(`proposal.skill.${skill}`))
          : s.caces = [this.props.t(`proposal.skill.${skill}`)]
        return
      }
      if(skill < 17) {
        s.meca ? s.meca.push(this.props.t(`proposal.skill.${skill}`))
          : s.meca = [this.props.t(`proposal.skill.${skill}`)]
        return
      }
      if(skill < 21 || skill === 28) {
        s.wms ? s.wms.push(this.props.t(`proposal.skill.${skill}`))
          : s.wms = [this.props.t(`proposal.skill.${skill}`)]
        return
      }
      if(skill < 28) {
        s.domain ? s.domain.push(this.props.t(`proposal.skill.${skill}`))
          : s.domain = [this.props.t(`proposal.skill.${skill}`)]
        return
      }
      s.others ? s.others.push(this.props.t(`proposal.skill.${skill}`))
        : s.others = [this.props.t(`proposal.skill.${skill}`)]
    })
    return s
  }

  async _propose() {
    await ProfileApiClient.propose({
      idService: this.props.profile.id,
      startDate: this.props.profile.startDate,
      endDate: this.props.profile.endDate,
      taskForceId: this.props.need.id,
      description: `Proposé pour le GIID : ${this.props.need.giid.title}`,
      giidId : this.props.need.giid.id,
      needAuthorId : parseInt(this.props.need.giid.author)
    }).then(()=> this.setState(prevState => {
      prevState.previousProfileId = this.props.profile.id
      return prevState
    })).catch(() => this.setState({error: this.props.t('profile.error.propose')}))
  }

  async _revokeProposal() {
    await ProfileApiClient.revoke({
      idService: this.props.profile.id,
      startDate: this.props.profile.startDate,
      endDate: this.props.profile.endDate,
      idTaskForce: this.props.need.id
    }).then(()=> this.setState(prevState=> {
      prevState.previousProfileId = 0
      return prevState
    })).catch(() => this.setState({error: this.props.t('profile.error.revoke')}))
  }

  render() {
    if(this.state.noSkillsFound === true)
      return <div className={`${this.className}__errorMessage`}>
        <div className={`${this.className}__errorHead`}>
          <div className={`${this.className}__errorTitle`}>
            {this.props.t('profile.notFound')}
          </div>
        </div>
        <div className={`${this.className}__errorActions`}>
          <button className={`${this.className}__errorActions__back`}
            onClick={()=> this.props.onGoBack()}>
            <FontAwesomeIcon  className={`${this.className}__errorActions__backIcon`} icon={faChevronLeft}/>
            <div className={`${this.className}__errorActions__backReturn`}>
              {this.props.t('action.return')}
            </div>
          </button>
        </div>
      </div>

    return <div className={this.className}>
      <div className={`${this.className}__head`}>
        <div className={`${this.className}__title`}>
          {this.props.t('profile.title')}
        </div>
        <div className={`${this.className}__need`}>
          <div className={`${this.className}__need__icon`}>
            <FontAwesomeIcon icon={faUser}/>
          </div>
          <div className={`${this.className}__need__resource`}>
            {this.props.need?.resource}
          </div>
        </div>
        {this.state.previousProfileId === this.props.profile.id &&
            <div className={`${this.className}__proposed-profile`}>{this.props.t('profile.alreadyProposed')}</div>
        }
      </div>
      <div className={`${this.className}__info`}>
        <div className={`${this.className}__info__head`}>
          <div className={`${this.className}__info__label`}>{this.props.profile.label}</div>
        </div>
        <div className={`${this.className}__skills`}>
          {Object.keys(this.state.groupedSkills).length ?
            Object.entries(this.state.groupedSkills).filter(entry=> entry[1].length > 0).map(([key, value], i) =>
              <div className={`${this.className}__info-skills__map`} key={i}>{this.props.t(`proposal.skill.label.${key}`)} :{' '}
                {value.map(v=> v.replace(new RegExp(this.props.t(`proposal.skill.label.${key}`) + '[ ]*'), '')).join(' / ')}
              </div>
            )
            :
            <div>{this.props.t(`proposal.skill.label.noSkills`)}</div>
          }
        </div>
      </div>
      {this.state.error && <div className={`${this.className}__error`}>{this.state.error}</div>}
      <div className={`${this.className}__actions`}>
        {this.state.previousProfileId !== this.props.profile.id ?
          <button className={`${this.className}__actions__proposal`}
            onClick={() => this._propose()}>
            {this.props.t('profile.propose')}
          </button>
          :
          <button className={`${this.className}__actions__revoke`} onClick={() => this._revokeProposal()}>
            {this.props.t('profile.revoke')}
          </button>
        }
        <button className={`${this.className}__actions__back`}
          onClick={()=> this.props.onGoBack()}>
          <div className={`${this.className}__actions__backIcon`}>
            <FontAwesomeIcon icon={faChevronLeft}/>
          </div>
          <div className={`${this.className}__actions__backReturn`}>
            {this.props.t('action.return')}
          </div>
        </button>
      </div>
    </div>
  }
}

export default withTranslation()(withRouter(ProfileDetails))
