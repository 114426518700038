class ProposalApiClient {

  /**
   * create a proposal
   * @param {object} params
   * @return {Promise<any>}
   */
  async create(params) {
    return fetch(window.location.origin + '/api/proposals', {
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(params),
      method: 'POST'
    }).then(function (response) {
      return response.json()
    })
  }

  /**
   * find an user's proposals
   * @param {int} userId
   * @param {int} page
   * @return {Promise<any>}
   */
  async find(userId, page) {
    const params = {
      author: userId
    }
    return await this.findBy(params, page ||1)
  }

  /**
   * find proposals
   * @param {object} params
   * @param {int=} page
   * @return {Promise<any>}
   */
  async findBy(params, page) {
    params = params || {}
    const url = new URL(window.location.origin + '/api/proposals')
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value)
    }
    if(page){
      url.searchParams.set('page', page)
    }
    const response = await fetch(url.toString())

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * find a proposal
   * @param {int} id
   * @return {Promise<any>}
   */
  async findOne(id) {
    const response = await fetch(window.location.origin + '/api/proposals/'+id)
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()

  }

  /**
   * decline a proposal
   * @param {object} params
   * @return {Promise<any>}
   */
  async decline(params) {
    const response = await fetch(window.location.origin + '/api/proposals/decline', {
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(params),
      method: 'PUT'
    })
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * accept a proposal
   * @param {object} params
   * @return {Promise<any>}
   */
  async accept(params) {
    const response = await fetch(window.location.origin + '/api/proposals/accept', {
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(params),
      method: 'PUT'
    })
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * find declined proposals
   * @param {object} params
   * @param {int} page
   * @return {Promise<any>}
   */
  async findDeclined(params, page) {
    params = params || {}
    const url = new URL(window.location.origin + '/api/proposals/declined')
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value)
    }
    if(page){
      url.searchParams.set('page', page)
    }
    const response = await fetch(url.toString())

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }
}

const proposalApiClient = new ProposalApiClient()
export default proposalApiClient
