import React from 'react'
import {getSubscription} from '../../lib/push-notification'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppContext from "../../context/app.context";

export class AskNotificationPermission extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorSubscription: false
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func, // withTranslation
      onContinue: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'askNotificationPermission'
  }

  componentDidMount() {
    if (!Object.prototype.hasOwnProperty.call(window, 'Notification')) {
      return this.props.onContinue()
    }
    if (Notification.permission === 'granted') {
      getSubscription().then(() => this.props.onContinue()).catch((e) => {
        console.error(e)
        this.setState({errorSubscription: true})
        this.props.onContinue()
      })
      if (this.state.loading) {
        this.setState({loading: !this.state.loading})
      }
      return
    }
    this.setState({loading: false})
  }

  async _authorized() {
    try {
      this.setState({loading: true})
      const consent = await Notification.requestPermission()
      if (consent === 'granted') {
        await getSubscription()
      }
      return this.props.onContinue()
    } catch (e) {
      console.error(e)
      this.setState({errors: [this.props.t('askNotificationPermission.errors.authorize')]})
    }
  }

  async _continue() {
    return this.props.onContinue()
  }

  render() {
    if (this.state.loading) {
      return (<div className={`${this.className}__loading`}>
        <FontAwesomeIcon icon={faSpinner}/>
      </div>)
    }
    return (
      <div className={this.className}>
        <div className={`${this.className}__title`}>
          {this.props.t('askNotificationPermission.title',
            {name: this.context.user.identity.usualName})}
        </div>
        {!this.state.errorSubscription && (
          <div className={`${this.className}__body`}>
            {this.props.t('askNotificationPermission.body')}
          </div>
        )}

        {!!this.state.errors?.length &&
        <div className={`${this.className}__actions`}>
          {this.state.errors.map((error, key) => (
            <div className={`${this.className}__error`} key={key}>
              {error}
            </div>
          ))}
        </div>}

        {this.state.errorSubscription && (
          <div className={`${this.className}__error`}>
            {this.props.t('askNotificationPermission.error')}
          </div>
        )}
        <div className={`${this.className}__actions`}>
          {!this.state.errorSubscription && (
            <button className={`${this.className}__authorize`}
              onClick={() => this._authorized()}>{this.props.t('askNotificationPermission.authorize')}
            </button>
          )}
          <button className={`${this.className}__continue`} onClick={() => this._continue()}>
            {this.props.t('askNotificationPermission.continue')}
          </button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(AskNotificationPermission)
