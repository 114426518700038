import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

class _Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: props.value ?? ''
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.string,
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'need-create-comment'
  }

  render() {
    return <div className={`${this.className}`}>
      <div className={`${this.className}__label`}>
        {this.props.t('needs.create.comment.label')}
      </div>
      <div className={`${this.className}__value`}>
        <textarea
          placeholder={this.props.t('needs.create.comment.placeholder')}
          value={this.state.comment}
          onChange={e => this.setState({comment: e.target.value})}
        />
      </div>
      <div className={`${this.className}__actions`}>
        <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
          {this.props.t('action.previous')}
        </button>
        <button className={`${this.className}__action`} onClick={() => this.props.onContinue(this.state.comment)}>
          {this.props.t('action.finish')}
        </button>
      </div>
    </div>
  }
}

export default {
  Step: withTranslation()(_Step)
}
