import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import categoryApiClient from "../../../service/category.api.client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../context/app.context";
import withRouter from '../../../lib/withRouter'

class _Step extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func
    }
  }

  get className() {
    return 'need-create-job'
  }

  componentDidMount() {
    categoryApiClient.findAll().then(({categories}) => this.setState({categories, loading: false})).catch(e => {
      console.error(e)
      if (e.status === 401) {
        this.context.logout()
        return
      }
      this.setState(prevState => {
        prevState.errors = prevState.errors || []
        prevState.errors.push(this.props.t('needs.create.job.errors.loading'))
        prevState.loading = false
        return prevState
      })
    })
  }

  _select(type) {
    this.props.onContinue(type)
  }

  render() {
    const {
      categories,
      errors,
      loading
    } = this.state

    if (loading) {
      return (<div className={`${this.className}__loading`}>
        <FontAwesomeIcon icon={faSpinner}/>
      </div>)
    }
    if (errors) {
      return <div className={this.className}>
        <div className={`${this.className}__errors`}>
          {errors.map((error, key) => (
            <div className={`${this.className}__error`} key={key}>
              {error}
            </div>)
          )}
        </div>
      </div>
    }
    return <div className={this.className}>
      <div className={`${this.className}__label`}>
        {this.props.t('needs.create.job.label')}
      </div>
      <div className={`${this.className}__categories`}>
        {categories.map((category, key) => <div className={`${this.className}__category`} key={key}>
          <div className={`${this.className}__category__label`}>
            {category.label}
          </div>
          <div className={`${this.className}__category__types`}>
            {category.types.map((type, key) => (
              <button className={`${this.className}__type`}
                key={key}
                onClick={() => {
                  this._select({...type, category})
                }}>
                <div className={`${this.className}__type__label`}>
                  {type.label}
                </div>
                <FontAwesomeIcon icon={faChevronRight}/>
              </button>
            ))}
          </div>
        </div>)}
      </div>
      <div className={`${this.className}__actions`}>
        <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
          {this.props.t('action.previous')}
        </button>
      </div>
    </div>
  }
}

class _Label extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.object.isRequired
    }
  }

  render() {
    return <>{this.props.value.category.label} / {this.props.value.label}</>
  }
}

export default {
  Step: withTranslation()(withRouter(_Step)),
  Label: withTranslation()(withRouter(_Label))
}
