import React from 'react'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight, faCheckCircle} from '@fortawesome/free-solid-svg-icons'

export class OfferTeaser extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func,
      offer: PropTypes.object.isRequired,
      onShow: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'offer-teaser'
  }

  render() {
    if (!this.props.offer) {
      return null
    }
    return (
      <button className={`${this.className}${(this.props.offer.isSelected ? ' selected' : '')}`}
        onClick={()=> this.props.onShow(this.props.offer)}>
        <div className={`${this.className}__info`}>
          <div className={`${this.className}__info__name`}>
            {this.props.offer.label}
          </div>
          <div className={`${this.className}__info__company`}>
            {this.props.offer.company.name}
          </div>
        </div>
        {this.props.offer.isSelected &&
        <FontAwesomeIcon className={`${this.className}__selected-icon`} icon={faCheckCircle}/>
        }
        <FontAwesomeIcon className={`${this.className}__action-icon`} icon={faChevronRight}/>
      </button>
    )
  }
}

export default withTranslation()(withRouter(OfferTeaser))
