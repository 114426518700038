import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import moment from 'moment'
import 'moment/locale/fr'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBell} from '@fortawesome/free-solid-svg-icons'
import Markdown from 'react-markdown'
import {Link} from 'react-router-dom'

export class GiidTeaser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      giid: props.giid
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func, // withTranslation
      giid: PropTypes.object.isRequired,
      scope: PropTypes.string.isRequired
    }
  }

  get className() {
    return 'giid-teaser'
  }

  get mainClassName() {
    switch(true) {
    case (this.props.scope === 'mines' && this.state.giid.hasSelectedNeeds === true) :
    case (this.props.scope !== 'mines' && this.state.giid.hasAcceptedProfiles > 0) :
      return `${this.className} with-selected-profile`
    case (this.props.scope !== 'mines' && this.state.giid.profilesAvailable) :
      return `${this.className} with-profile`
    case (this.props.scope !== 'mines' && this.state.giid.hasProposedProfile) :
      return `${this.className} with-proposed-profile`
    default: return this.className
    }
  }

  get teaserMessage() {
    switch(true) {
    case (this.props.scope === 'mines' && this.state.giid.hasSelectedNeeds === true) :
      return this.props.t('needs.hasAcceptedProfile')
    case (this.props.scope !== 'mines' && this.state.giid.hasAcceptedProfiles > 0 && this.state.giid.profilesAvailable) :
      return <div>
        <div>{(this.state.giid.totalNeeds > 1 ?
          this.props.t('giid.withProfileAccepted', {count: this.state.giid.hasAcceptedProfiles ?? 1}) :
          this.props.t('needs.withProfileAccepted'))}
        </div>
        <div>{this.props.t('needs.hasProfile')}</div>
      </div>

    case (this.props.scope !== 'mines' && this.state.giid.hasAcceptedProfiles > 0) :
      return (this.state.giid.totalNeeds > 1 ?
        this.props.t('giid.withProfileAccepted', {count: this.state.giid.hasAcceptedProfiles ?? 1}) :
        this.props.t('needs.withProfileAccepted'))
    case (this.props.scope !== 'mines' && this.state.giid.profilesAvailable) :
      return this.props.t('needs.hasProfile')
    case (this.props.scope !== 'mines' && this.state.giid.hasProposedProfile) :
      return this.props.t('needs.hasProposedProfile')
    default : return null
    }
  }

  __blank() {
    return <div className={`${this.className} blank`}>
      <div className={`${this.className} blank__title`}> </div>
      <div className={`${this.className} blank__client`}> </div>
      <div className={`${this.className} blank__period`}> </div>
      <div className={`${this.className} blank__total-needs`}> </div>
    </div>
  }

  render() {
    if(!this.state.giid) {
      if(!this.props.giid) {
        return null
      }
      return this.__blank()
    }
    if(this.state.error) {
      return <div className={`${this.className}__error`}>{this.state.error}</div>
    }
    return (
      <Link className={this.mainClassName} to={`/needs/${this.props.scope}/${this.state.giid.id}`}>
        <div className={`${this.className}__info`}>
          <Markdown className={`${this.className}__title`}>{this.props.t('giid.title', {title: this.state.giid.title})}</Markdown>
          <Markdown className={`${this.className}__client`}>{this.props.t('giid.client', {client: this.state.giid.client})}</Markdown>
          <div className={`${this.className}__teaserMessage`}>
            {this.teaserMessage}
          </div>

          <div className={`${this.className}__period`}>
            {this.props.t('giid.period', {start: moment(this.state.giid.startDate, 'X').format('LL'),
              end: moment(this.state.giid?.endDate, 'X').format('LL')})}
          </div>
          <div className={`${this.className}__total-needs`}>
            {this.props.t('giid.countNeed', {count: this.state.giid.totalNeeds})}
          </div>
        </div>
        {(this.props.scope === 'mines' && this.state.giid.totalProposals > 0) &&
            <div className={`${this.className}__proposals`}>
              <FontAwesomeIcon icon={faBell}/>
              <span>{this.state.giid.totalProposals}</span>
            </div>
        }
      </Link>
    )
  }
}

export default withTranslation()(withRouter(GiidTeaser))
