import React from 'react'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import PropTypes from 'prop-types'
import ProposalApiClient from '../../service/proposal.api.client'
import NeedApiClient from '../../service/need.api.client'
import OfferTeaser from './offer.teaser'
import OfferDetails from './offer.details'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser} from '@fortawesome/free-solid-svg-icons'

export class OfferList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offers: [],
      selectedOffer: undefined
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func
    }
  }

  get className() {
    return 'offers'
  }

  async componentDidMount() {
    const needId = parseInt(window.location.pathname.split('/').slice(-1)[0])
    const { need } = await NeedApiClient.findOne(needId)
    const {proposals} = await ProposalApiClient.findBy({need: needId})
    this.setState({offers : proposals, need})
  }

  /**
   * update the list after an offer has been declined
   * @param {object} offer
   * @private
   */
  _offerDeclined(offer) {
    this.setState(prevState => {
      prevState.selectedOffer = undefined
      prevState.offers[prevState.offers.findIndex(o=> o === offer)].isActive = false
      return prevState
    })
  }

  /**
   * update the list after an offer has been accepted
   * @param {object} offer
   * @private
   */
  async _offerAccepted(offer) {
    this.setState(prevState => {
      prevState.selectedOffer = undefined
      prevState.offers[prevState.offers.findIndex(o=> o === offer)].isSelected = true
      prevState.offers.filter(o => o !== offer).map(o => {
        o.isActive = false
      })
      return prevState
    })
  }

  render() {
    return(
      <div className={this.className}>
        <div className={`${this.className}__info__label`}>
          {this.props.t('offers.list.title')}
        </div>
        <div className={`${this.className}__info__need-resource`}>
          <FontAwesomeIcon icon={faUser}/>
          {this.state.need?.resource}
        </div>
        {this.state.offers.filter(offer => offer.isActive).length === 0 &&
        <div className={`${this.className}__list__empty`}>{this.props.t('offers.list.empty')}</div>
        }
        {!this.state.selectedOffer ?
          <div className={`${this.className}__list`}>
            {this.state.offers.filter(offer => offer.isActive).map((offer, key) =>
              <OfferTeaser key={key} offer={offer} onShow={(offer) => this.setState({selectedOffer: offer})}/>
            )}
          </div> :
          <div>
            <OfferDetails offer={this.state.selectedOffer}
              giid={this.state.need.giid}
              onGoBack={()=> this.setState({selectedOffer: undefined})}
              onDecline={(offer) => this._offerDeclined(offer)}
              onAccept={(offer) => this._offerAccepted(offer)}
            />
          </div>
        }
      </div>

    )
  }
}

export default withTranslation()(withRouter(OfferList))
