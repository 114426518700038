import http from './http'

const pushServerPublicKey = 'BIN2Jc5Vmkmy-S3AUrcMlpKxJpLeVRAfu9WBqUbJ70SJOCWGCGXKY-Xzyh7HDr6KbRDGYHjqZ06OcS3BjD7uAm8'

/**
 * creates a push and save in API a notification subscription of the registered service worker
 */
async function getSubscription () {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready
  let subscription = await serviceWorker.pushManager.getSubscription()

  if (!subscription) {
    // subscribe and return the subscription
    subscription = await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: pushServerPublicKey
    })
  }
  // send the subscription to the IDShare API
  return http.post('/api/web-notification/subscription', {
    subscription
  })
}

export {
  getSubscription
}
