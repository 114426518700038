import React from 'react'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import NeedApiClient from '../../service/need.api.client'
import NeedTeaser from './need.teaser'
import withRouter from '../../lib/withRouter'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import GiidApiClient from "../../service/giid.api.client";
import ProfileApiClient from "../../service/profile.api.client";
import CompanyNotifiedApiClient from "../../service/company.notified.api.client";
import {NO_PROFILE_ASWER} from "../../helper/constants";
import {Link} from "react-router-dom";

export class NeedsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      needs: [],
      page: 1,
      total : 0,
      next: false,
      previous: false,
      giidId: props.params.id
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func, // withTranslation
      scope: PropTypes.string.isRequired,
      params: PropTypes.object
    }
  }

  get className() {
    return 'needs'
  }

  async componentDidMount() {
    await this._load(this.state.page)
  }

  _find(...props) {
    return this.props.scope === 'mines' ? NeedApiClient.findMines(...props) :
      NeedApiClient.findOthers(...props)
  }

  async _load(page) {
    this.setState({loading: true})
    if (page) {
      this.setState({page})
    }
    const user = JSON.parse(localStorage.getItem('user'))
    try {
      const [giid, {profiles}] = await Promise.all([GiidApiClient.findById(this.state.giidId),
        ProfileApiClient.findMyProfiles()])

      const sortedProfilesForAllNeeds = []
      giid.needs.map(async n => {
        const sortedProfileForOneNeed = await this._sortProfiles(n, profiles)
        if (sortedProfileForOneNeed.length) {
          sortedProfileForOneNeed.map(p => {
            if(!sortedProfilesForAllNeeds.some(profile => profile.id === p.id)) {
              sortedProfilesForAllNeeds.push(p)
            }
          })
        }
      })

      const { hasAlreadyAnswered, action } = await CompanyNotifiedApiClient.hasAlreadyAnswered(this.state.giidId)
      const {needs, total, nextPage, hasNextPage, hasPrevPage} = await this._find(user.group?.company?.id, page || 1, {giid: this.state.giidId})
      this.setState({
        needs,
        total,
        nextPage,
        next: hasNextPage,
        previous: hasPrevPage,
        sortedProfilesForAllNeeds,
        hasAlreadyAnswered,
        action : parseInt(action),
        loading: false
      })
    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('needs.list.error'), loading: false})
    }
  }

  async _seeAll(user, needs, page) {
    this.setState({loading: true})
    try {
      const needsData = await this._find(user.group?.company?.id, page, {giid: this.state.giidId})
      needsData.needs.map(n => needs.push(n))
      if(needsData.hasNextPage){
        await this._seeAll(user, needs, needsData.nextPage)
      }
      else {
        this.setState({
          needs,
          total: needsData.total,
          nextPage: needsData.nextPage,
          next: needsData.hasNextPage,
          previous: needsData.hasPrevPage,
          loading: false
        })
      }
    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('needs.list.error'), loading: false})
    }
  }

  async _findNeedsFilter() {
    this.setState({loading: true})
    const user = JSON.parse(localStorage.getItem('user'))
    try {
      const needsData = await this._find(user.group?.company?.id, this.state.page || 1,
        {resource: this.state.filter.trim(), giid: this.state.giidId})
      this.setState(prevState => {
        prevState.needs = needsData.needs
        prevState.total = needsData.total
        prevState.nextPage = needsData.nextPage
        prevState.next = needsData.hasNextPage
        prevState.previous = needsData.hasPrevPage
        prevState.loading = false
        return prevState
      })
    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('needs.list.error'), loading: false})
    }
  }


  async _getFilter(event) {
    if(event.charCode === 13) {
      await this._findNeedsFilter()
      return
    }
    this.setState(prevState => {
      prevState.filter = event.target.value
      return prevState
    })
  }

  /**
   * Sort profiles
   * @param {object} need
   * @param {object[]} profiles
   * @return {Promise<*>}
   * @private
   */
  async _sortProfiles(need, profiles) {
    const profilesMatch = profiles?.filter(profile => {
      if (profile.abilities.some(ability => ability.id === need?.job.type.id)) {
        return profile
      }
    }).map(profile => profile.id)
    try {
      const {availableProfiles} = await ProfileApiClient.getAvailableProfiles({
        profilesId: profilesMatch,
        idTaskForce: need.id
      })
      return availableProfiles.map(profile => {
        return {
          ...profile,
          matchIndicator: Number.parseFloat((profile.skills.filter(s => need.skills.includes(s)).length)
              / ((need.skills.length || 0)), 2)
        }
      }).sort((a, b) => b.matchIndicator - a.matchIndicator)
    } catch (err) {
      console.error(err)
      this.setState({error: this.props.t('profile.error.load')})
    }
  }

  async _notifyNoProfile() {
    ProfileApiClient.answerNoProfile(this.state.giidId).then(() => this.setState({answerSent: true}))
  }

  __blank(key) {
    return <div className={`${this.className} blank`} key={key}>
      <div className={`${this.className} blank__title`}> </div>
      <div className={`${this.className} blank__client`}> </div>
      <div className={`${this.className} blank__period`}> </div>
      <div className={`${this.className} blank__total-needs`}> </div>
    </div>
  }

  render() {
    return (
      <div className={this.className}>
        <div className={`${this.className}__demand__response-title`}>
          {this.props.scope === 'mines' ? this.props.t('needs.title.mines') : this.props.t('needs.title.others')}
        </div>
        <div className={`${this.className}__searchBar`}>
          <input type='search'
            placeholder={this.props.t('needs.search.placeholder')}
            value={this.state.filter}
            onChange={event => this._getFilter(event)}
            onKeyPress={event => this._getFilter(event)}/>
          <button className={`${this.className}__searchIcon`}>
            <FontAwesomeIcon icon={faSearch}
              onClick={()=> this._findNeedsFilter()}/>
          </button>
        </div>
        {this.state.loading &&
            <>
              <div className={`${this.className} blank__count`}></div>
              {[1, 2, 3, 4].map(i => this.__blank(i))}
            </>
        }
        {!this.state.loading && (
          !this.state.needs.length ?
            <div className={`${this.className}__list`}>
              <div className='need-teaser'>
                <div className='need-teaser__info'>
                  {
                    this.props.scope === 'mines'
                      ? this.props.t('needs.list.mines.none')
                      : this.props.t('needs.list.others.none')
                  }
                </div>
              </div>
            </div>
            :
            <div className={`${this.className}__list`}>
              <div className={`${this.className}__list__count`}>
                {this.props.scope === 'mines'
                  ? this.props.t('needs.list.mines.count', { count: this.state.total })
                  : this.props.t('needs.list.others.count', { count: this.state.total })
                }
              </div>
              {this.state.needs.map(need => {
                return (
                  <NeedTeaser
                    key={need.id}
                    need={need}
                    scope={this.props.scope}
                  />
                )
              })}
            </div>
        )}
        {this.state.error &&
          <div className={`${this.className}__error`}>{this.state.error}</div>
        }
        <div className={`${this.className}__actions`}>
          {this.state.previous &&
          <button className={`${this.className}__actions__less`}
            onClick={() => this._load(this.state.page - 1)}>
            {this.props.t('needs.list.previous')}
          </button>
          }
          {this.state.next &&
          <>
            <button className={`${this.className}__actions__more`}
              onClick={() => this._load(this.state.page + 1)}>
              {this.props.t('needs.list.next')}
            </button>
            <button className={`${this.className}__actions__all`}
              onClick={ async () => await this._seeAll(JSON.parse(localStorage.getItem('user')), [], 1)}>
              {this.props.t('needs.seeAll')}
            </button>
          </>
          }
        </div>
        {(this.props.scope === 'mines' && this.state.needs?.length > 0) &&
        <Link className={`${this.className}__list__create`}
          to={`/needs/create/${this.state.giidId}`}>
          {this.props.t('needs.create.label')}
        </Link>
        }
        {this.props.scope !== 'mines' && (
          (this.state.hasAlreadyAnswered === true && this.state.action === NO_PROFILE_ASWER) ?
            <div className={`${this.className}__already-answer`}>
              {this.props.t('giid.alreadyAnswerNoProfile')}
            </div> :
            this.state.sortedProfilesForAllNeeds?.length === 0 &&
                <button className={`${this.className}__actions__noprofilesforgiid`}
                  onClick={() => this._notifyNoProfile()}>
                  {this.props.t('needs.request.action.noProfile')}
                </button>
        )}
      </div>
    )
  }
}

export default withTranslation()(withRouter(NeedsList))
