const host = window.location.origin

function post (path, body) {
  return fetch(`${host}${path}`, {
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(body),
    method: 'POST'
  }).then(function (response) {
    return response.json()
  }).then(function (data) {
    return data
  })
}

function get (path) {
  return fetch(`${host}${path}`, {
    method: 'GET'
  }).then(function (response) {
    return response.json()
  }).then(function (data) {
    return data
  })
}

const http = {
  post: post,
  get: get
}

export default http
