import React from 'react'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import PropTypes from 'prop-types'
import 'moment/locale/fr'
import NeedApiClient from '../../service/need.api.client'
import ProposalApiClient from '../../service/proposal.api.client'
import RequestInfo from "./request.info";
import ProfileApiClient from "../../service/profile.api.client";
import GiidApiClient from "../../service/giid.api.client";
import CompanyNotifiedApiClient from "../../service/company.notified.api.client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {NO_PROFILE_ASWER} from "../../helper/constants";
import {Link} from 'react-router-dom'

export class RequestOther extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      need: undefined,
      proposals: [],
      messages: 0,
      answerSent: false
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func,
      navigate: PropTypes.func
    }
  }

  get className() {
    return 'request'
  }

  async componentDidMount() {
    const requestId = parseInt(window.location.pathname.split('/').slice(-1)[0])
    const {need} = await NeedApiClient.findOne(requestId)
    let {needs} = await GiidApiClient.findById(need.giid.id)
    const {proposals, totalDeclined} = await ProposalApiClient.findBy({need: requestId})

    const {profiles} = await ProfileApiClient.findMyProfiles()
    const sortedProfiles = await this._sortProfiles(need, profiles)
    const sortedProfilesForAllNeeds = sortedProfiles

    needs.map(async n => {
      const sortedProfileForOneNeed = await this._sortProfiles(n, profiles)
      if (sortedProfileForOneNeed.length) {
        sortedProfileForOneNeed.map(p => {
          if(!sortedProfilesForAllNeeds.some(profile => profile.id === p.id)) {
            sortedProfilesForAllNeeds.push(p)
          }
        })
      }
    })

    const { hasAlreadyAnswered, action } = await CompanyNotifiedApiClient.hasAlreadyAnswered(need.giid.id)
    this.setState({
      need,
      proposals,
      profiles,
      needs: needs,
      answerSent : hasAlreadyAnswered,
      action : parseInt(action),
      sortedProfiles,
      sortedProfilesForAllNeeds,
      declinedProposals: totalDeclined
    })
  }

  /**
   * Sort profiles
   * @param {object} need
   * @param {object[]} profiles
   * @return {Promise<*>}
   * @private
   */
  async _sortProfiles(need, profiles) {
    const profilesMatch = profiles?.filter(profile => {
      if (profile.abilities.some(ability => ability.id === need?.job.type.id)) {
        return profile
      }
    }).map(profile => profile.id)
    try {
      const {availableProfiles} = await ProfileApiClient.getAvailableProfiles({
        profilesId: profilesMatch,
        idTaskForce: need.id
      })
      return availableProfiles.map(profile => {
        return {
          ...profile,
          matchIndicator: Number.parseFloat((profile.skills.filter(s => need.skills.includes(s)).length)
                  / ((need.skills.length || 0)), 2)
        }
      }).sort((a, b) => b.matchIndicator - a.matchIndicator)
    } catch (err) {
      console.error(err)
      this.setState({error: this.props.t('profile.error.load')})
    }
  }

  async _notifyNoProfile() {
    ProfileApiClient.answerNoProfile(this.state.need.giid.id).then(() => this.setState({answerSent: true}))
  }


  render() {
    if (!this.state.need) {
      if(parseInt(window.location.pathname.split('/').slice(-1)[0])) {
        return (<div className={`${this.className}__loading`}>
          <FontAwesomeIcon icon={faSpinner}/>
        </div>)
      }
      return null
    }
    return (
      <div className={this.className}>
        <RequestInfo
          need={this.state.need}
          scope='other'
        />
        {this.state.error ?
          <div className={`${this.className}__error`}>{this.state.error}</div> :
          <div className={`${this.className}__actions`}>
            {this.state.answerSent === true && this.state.action === NO_PROFILE_ASWER ?
              <div className={`${this.className}__info__already-answer`}>
                {this.props.t('needs.request.info.alreadyAnswerNoProfile')}
              </div> :
              this.state.sortedProfilesForAllNeeds.length === 0 ?
                <>
                  <button className={`${this.className}__action__noprofilesforgiid`}
                    onClick={() => this._notifyNoProfile()}>
                    {this.props.t('needs.request.action.noProfile')}
                  </button>
                  <Link className={`${this.className}__action__new-profile`}
                    to={`/${this.state.need.id}/profile/create`}>
                    {this.props.t('profile.create.label')}
                  </Link>
                </> :
                this.state.sortedProfiles.length ?
                  <Link className={`${this.className}__action__do-proposal`}
                    to={`/needs/makeProposal/${this.state.need.id}`}>
                    {this.props.t('needs.request.action.answer')}
                  </Link>
                  : <div>
                    <div className={`${this.className}__info__noProfiles`}>
                      {this.props.t('needs.request.action.otherProfile')}
                    </div>
                    <Link className={`${this.className}__action__new-profile`}
                      to={`/${this.state.need.id}/profile/create`}>
                      {this.props.t('profile.create.label')}
                    </Link>
                  </div>
            }
            {this.state.declinedProposals > 0 &&
              <Link className={`${this.className}__action__others`} to={`/declined/${this.state.need.id}`}>
                {this.props.t('needs.request.action.declinedProposals')}
              </Link>
            }
            <Link className={`${this.className}__action__back`}
              to={`/needs/others/${this.state.need.giid.id}`}>
              <FontAwesomeIcon icon={faChevronLeft}/>
              {this.props.t('action.return')}
            </Link>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(withRouter(RequestOther))
