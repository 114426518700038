import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {PHONE_PATTERN} from "../../../helper/constants";

class _Step extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: {
        ...(props.value ?? {}),
        period1: 1,
        fromh1: 17,
        toh1: 33
      },
      errors: {}
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.object,
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'need-create-entry'
  }

  _change(key, value) {
    this.setState(prevState => {
      prevState.value[key] = value
      if(prevState.errors[key]) {
        delete prevState.errors[key]
      }

      if(prevState.errors.days && ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
        .findIndex(day => `${day}1` === key) !== -1) {
        delete prevState.errors.days
      }
      return prevState
    })
  }

  get schedule() {
    return this.props.t('needs.create.entry.schedule', {returnObjects: true})
  }

  get errors() {
    return {
      name1: (!this.state.value.name1 || this.state.value.name1.length < 2) && this.props.t('needs.create.errors.managerName'),
      phone1: [!this.state.value.phone1, !PHONE_PATTERN.test(this.state.value.phone1)]
        .findIndex(condition => condition === true) > -1 && this.props.t('needs.create.errors.managerPhone'),

      days: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].findIndex(day => this.state.value[`${day}1`]
          && this.state.value[`${day}1`] === true) === -1 && this.props.t('needs.create.errors.daysMissing')
    }
  }

  _valueChecker(value, name) {
    return name.startsWith('fromh') ? (this.state.value[`toh${name[name.length - 1]}`] && this.state.value[`toh${name[name.length - 1]}`] <= value)
      : (this.state.value[`fromh${name[name.length - 1]}`] && this.state.value[`fromh${name[name.length - 1]}`] >= value)
  }

  _continue() {
    if(Object.keys(this.errors).findIndex(key => this.errors[key]) > -1) {
      this.setState({errors: this.errors})
      return
    }
    this.props.onContinue(this.state.value)
  }

  __checkbox(className, label, name) {
    return <>
      <span>{label}</span>
      <input
        type="checkbox"
        checked={this.state.value[name]}
        onChange={(event)=> this._change(name, event.target.checked)}/>
    </>
  }

  __select(label, options, name) {
    return <>
      <span>{label}</span>
      <select value={this.state.value[name]}
        onChange={(e) => this._change(name, parseInt(e.target.value))}>
        {Object.entries(options).filter(([value]) => Number.isInteger(parseInt(value))).map(([value, label]) =>
          <option value={value} key={value} disabled={this._valueChecker(value, name)}>{label}</option>
        )}
      </select>
    </>
  }

  __input(className, label, props, name) {
    return <>
      <label htmlFor={className}>
        {label}
      </label>
      <input id={className} {...props} value={this.state.value[name]} onChange={(event) => this._change(name, event.target.value)}/>
    </>
  }

  __item(className, key) {
    return (
      <div className={className} key={key}>
        <div className={`${className}__schedule`}>
          <div className={`${className}__schedule__start`}>{this.__select(this.props.t('needs.create.entry.schedule.from'), this.schedule, `fromh${key}`)}</div>
          <div className={`${className}__schedule__end`}>{this.__select(this.props.t('needs.create.entry.schedule.to'), this.schedule, `toh${key}`)}</div>
        </div>
        <div className={`${className}__days ${this.state.errors?.days ? 'withError' : ''}`}>
          {['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].map((day, i) =>
            <div key={i} className={`${className}__days__item`}>
              {this.__checkbox(`${className}__left__days__item__checkbox`, this.props.t(`needs.create.entry.days.${day}`), `${day}${key}`)}</div>
          )}
        </div>
        {this.state.errors.days &&
            <div className={`${this.className}__error`}>{this.state.errors?.days}</div>
        }
        <div className={`${className}__entry ${this.state.errors?.name1 ? 'withError' : ''}`}>
          {this.__input(`${className}__entry__input`, this.props.t('needs.create.entry.manager.title'),
            {placeholder: this.props.t('needs.create.entry.manager.info'), required: true}, `name${key}`)}
        </div>
        {this.state.errors.name1 &&
            <div className={`${this.className}__error`}>{this.state.errors?.name1}</div>
        }
        <div className={`${className}__entry ${this.state.errors?.phone1 ? 'withError' : ''}`}>
          {this.__input(`${className}__entry__input`, this.props.t('needs.create.entry.phone'),
            {placeholder: this.props.t('needs.create.entry.phone'), required: true, inputMode: 'tel'}, `phone${key}`)}
        </div>
        {this.state.errors.phone1 &&
            <div className={`${this.className}__error`}>{this.state.errors?.phone1}</div>
        }
      </div>
    )
  }

  render() {
    return <div className={this.className}>
      <div className={`${this.className}__label`}>{this.props.t('needs.create.entry.label')}</div>
      {this.__item(`${this.className}__item`, 1)}
      <div className={`${this.className}__actions`}>
        <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
          {this.props.t('action.previous')}
        </button>
        <button className={`${this.className}__action ${Object.keys(this.errors).findIndex(key => 
          this.errors[key]) > -1 ? 'disabled': ''}`}
        onClick={() => this._continue(this.state.value)}>
          {this.props.t('action.next')}
        </button>
      </div>
    </div>
  }
}

class _Label extends React.Component {
  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.object.isRequired
    }
  }

  _scheduleClassifier(value) {
    const result = {}
    Object.keys(value).map(key => {
      result[key[key.length - 1]] = result[key[key.length - 1]] ?? {}
      result[key[key.length - 1]][key] = value[key]
    })
    return result
  }

  _summaryBuilder(result) {
    let res = ''
    const filter = ['name', 'phone', 'period']
    Object.keys(result).filter(key=> key && !filter.some(k => key.startsWith(k))).map(key => {
      if(key.startsWith('from')) {
        res += `De ${this.props.t(`needs.create.entry.schedule.${result[key]}`)} `
        return
      }
      if(key.startsWith('to')) {
        res += `à ${this.props.t(`needs.create.entry.schedule.${result[key]}`)} : `
        return
      }
      res += `${this.props.t(`needs.create.entry.summary.days.${key.slice(0, key.length -1)}`)} `
    })
    return res
  }


  render() {
    const schedule = this._scheduleClassifier(this.props.value)
    if(Object.values(schedule).length === 0) {
      return <>{this.props.t('needs.create.entry.none')}</>
    }

    return <>{Object.values(schedule).map(v => this._summaryBuilder(v)).join(' / ')}</>
  }
}

export default {
  Step: withTranslation()(_Step),
  Label: withTranslation()(_Label)
}
