import React from 'react'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import GiidApiClient from '../../service/giid.api.client'
import GiidTeaser from './teaser'
import withRouter from '../../lib/withRouter'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";


export class GiidList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      giids: [],
      page: 1,
      total : 0,
      next: false,
      previous: false
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func, // withTranslation
      scope: PropTypes.string.isRequired
    }
  }

  get className() {
    return 'giid-list'
  }

  async componentDidMount() {
    await this._load(this.state.page)
  }

  async componentDidUpdate(prevProps) {
    if(prevProps.scope !== this.props.scope) {
      await this._load(this.state.page)
    }
  }

  _find(...props) {
    return this.props.scope === 'mines' ? GiidApiClient.findMines(...props) : GiidApiClient.findOthers(...props)
  }

  async _load(page) {
    this.setState({loading: true})
    if (page) {
      this.setState({page})
    }
    const user = JSON.parse(localStorage.getItem('user'))
    try {
      const {giids, total, nextPage, hasNextPage, hasPrevPage} = await this._find(user.group?.company?.id, page || 1)
      this.setState({
        giids,
        total,
        nextPage,
        hasNextPage,
        hasPrevPage,
        loading: false
      })
    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('giid.list.error'), loading: false})
    }
  }

  async _seeAll(user, giids, page) {
    try {
      this.setState({loading: true})
      const giidsData = await this._find(user.group?.company?.id, page)
      giidsData.giids.map(giid => giids.push(giid))
      if(giidsData.hasNextPage){
        await this._seeAll(user, giids, giidsData.nextPage)
      }
      else {
        this.setState({
          giids,
          total: giidsData.total,
          nextPage: giidsData.nextPage,
          next: giidsData.hasNextPage,
          previous: giidsData.hasPrevPage,
          loading: false
        })
      }
    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('giid.list.error'), loading: false})
    }
  }

  async _findGiidsFilter() {
    const user = JSON.parse(localStorage.getItem('user'))
    try {
      this.setState({loading: true})
      const {giids, total, nextPage, hasNextPage, hasPrevPage} = await this._find(user.group?.company?.id, this.state.page || 1,
        {title: this.state.filter?.trim() ?? ''})
      this.setState(prevState => {
        prevState.giids = giids
        prevState.total = total
        prevState.nextPage = nextPage
        prevState.next = hasNextPage
        prevState.previous = hasPrevPage
        prevState.loading = false
        return prevState
      })
    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('giid.list.error'), loading: false})
    }
  }


  async _getFilter(event) {
    if(event.charCode === 13) {
      await this._findGiidsFilter()
      return
    }
    this.setState(prevState => {
      prevState.filter = event.target.value
      return prevState
    })
  }

  __blank() {
    return <div className={`${this.className} blank`}>
      <div className={`${this.className} blank__title`}> </div>
      <div className={`${this.className} blank__client`}> </div>
      <div className={`${this.className} blank__period`}> </div>
      <div className={`${this.className} blank__total-needs`}> </div>
    </div>
  }

  render() {
    return (
      <div className={this.className}>
        <div className={`${this.className}__demand__response-title`}>
          {this.props.scope === 'mines' ? this.props.t('giid.mines') : this.props.t('giid.others')}
        </div>
        <div className={`${this.className}__searchBar`}>
          <input type='search'
            placeholder={this.props.t('giid.list.search.placeholder')}
            value={this.state.filter}
            onChange={event => this._getFilter(event)}
            onKeyPress={event => this._getFilter(event)}/>
          <button className={`${this.className}__searchIcon`}>
            <FontAwesomeIcon icon={faSearch}
              onClick={()=> this._findGiidsFilter()}/>
          </button>
        </div>
        {this.state.loading &&
            <>
              <div className={`${this.className} blank__count`}></div>
              {[1, 2, 3, 4].map(i =>
                this.__blank(i))}
            </>
        }
        {!this.state.loading && (
          !this.state.giids || this.state.giids.length === 0 ?
            <div className={`${this.className}__list__empty`}>
              {this.props.scope === 'mines'
                ? this.props.t('giid.list.mines.none')
                : this.props.t('giid.list.others.none')
              }
            </div>
            :
            <div className={`${this.className}__list ${this.props.scope === 'mines' ? '' : 'others'}`}>
              <div className={`${this.className}__list__count`}>
                {this.props.scope === 'mines'
                  ? this.props.t('giid.list.mines.count', {count: this.state.total} )
                  : this.props.t('giid.list.others.count', {count: this.state.total} )
                }
              </div>
              {this.state.giids.map(giid =>
                <GiidTeaser
                  key={giid.id}
                  giid={giid}
                  scope={this.props.scope}
                />
              )}
            </div>
        )}
        {this.state.error &&
                    <div className={`${this.className}__error`}>{this.state.error}</div>
        }
        <div className={`${this.className}__actions`}>
          {this.state.previous &&
              <button className={`${this.className}__actions__less`}
                onClick={() => this._load(this.state.page - 1)}>
                {this.props.t('giid.list.previous')}</button>
          }
          {this.state.next &&
              <>
                <button className={`${this.className}__actions__more`}
                  onClick={() => this._load(this.state.page + 1)}>
                  {this.props.t('giid.list.next')}
                </button>
                <button className={`${this.className}__actions__all`}
                  onClick={ async () => await this._seeAll(JSON.parse(localStorage.getItem('user')), [], 1)}>
                  {this.props.t('giid.list.seeAll')}
                </button>
              </>
          }
        </div>
        {this.props.scope === 'mines' &&
            <Link className={`${this.className}__list__create`} to={`/needs/create/${undefined}`}>
              {this.props.scope === 'mines' ? this.props.t('giid.create') :
                this.props.t('profile.create.label')}
            </Link>
        }
      </div>
    )
  }
}

export default withTranslation()(withRouter(GiidList))
