import React from 'react'
import {
  useNavigate,
  useParams,
  useLocation, useSearchParams
} from "react-router-dom";
export default function withRouter(C) {
  function ComponentWithNavigateProp(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    return (
      <C
        {...props}
        navigate={navigate}
        params={params}
        location={location}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    )
  }

  return ComponentWithNavigateProp;
}
