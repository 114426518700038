import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import NeedApiClient from '../../service/need.api.client'
import ProposalApiClient from '../../service/proposal.api.client'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faSpinner} from '@fortawesome/free-solid-svg-icons'
import Markdown from 'react-markdown'
import {Link} from 'react-router-dom'

export class DeclinedProposals extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      need: undefined
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func,
      need: PropTypes.object
    }
  }

  get className() {
    return 'proposal-declined'
  }

  async componentDidMount() {
    try {
      const requestId = parseInt(window.location.pathname.split('/').slice(-1)[0])
      const {need} = await NeedApiClient.findOne(requestId)
      const {proposals} = await ProposalApiClient.findDeclined({need: requestId})
      this.setState({need, proposals})
    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('profile.error.load')})
    }
  }

  render() {
    if (!this.state.need) {
      if(parseInt(window.location.pathname.split('/').slice(-1)[0])) {
        return (<div className={`${this.className}__loading`}>
          <FontAwesomeIcon icon={faSpinner}/>
        </div>)
      }
      return null
    }
    return (
      <div className={`${this.className}`}>
        {this.state.error ?
          <div className={`${this.className}__error`}>{this.state.error}</div> :
          <div className={`${this.className}__feed`}>
            <Markdown className={`${this.className}__title`}>
              {this.props.t('proposal.declined.title', {need: this.state.need.resource})}
            </Markdown>
            <div className={`${this.className}__list`}>
              {this.state.proposals.map((proposal, key) =>
                <div key={key} className={`${this.className}__item`}>
                  <div className={`${this.className}__item__name`}>{proposal.label}</div>
                  {proposal.declineReason &&
                      <div className={`${this.className}__item__reason`}>
                        {this.props.t('proposal.declined.reason', {reason: proposal.declineReason})}
                      </div>
                  }
                </div>
              )}
            </div>
          </div>
        }
        {this.state.proposals.length === 0 &&
            <div className={`${this.className}__empty`}>{this.props.t('proposal.declined.noProfiles')}</div>
        }

        <div className={`${this.className}__actions`}>
          <Link className={`${this.className}__actions__new-profile`}
            to={`/${this.state.need.id}/profile/create`}>
            {this.props.t('profile.create.label')}
          </Link>
          <Link className={`${this.className}__actions__back`}
            to={`/requestsOthers/${this.state.need.id}`}>
            <FontAwesomeIcon icon={faChevronLeft}/>
            {this.props.t('action.return')}
          </Link>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(DeclinedProposals))
