class ProfileApiClient {

  /**
   * create a profile
   * @param {object} params
   * @return {Promise<*>}
   */
  async create(params) {
    const response = await fetch(window.location.origin + '/api/profiles/create', {
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(params),
      method: 'POST'
    })
    if (!response.ok) {
      throw await response.json()
    }
    return await response
  }

  /**
   * propose a profile
   * @param {object} params
   * @return {Promise<*>}
   */
  async propose(params) {
    const response = await fetch(window.location.origin + '/api/profiles/propose', {
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(params),
      method: 'POST'
    })
    if (!response.ok) {
      throw await response.json()
    }
    return await response
  }

  /**
   * revoke a profile
   * @param {object} params
   * @return {Promise<*>}
   */
  async revoke(params) {
    const response = await fetch(window.location.origin + '/api/profiles/revoke', {
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(params),
      method: 'DELETE'
    })
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * get the previous profile
   * @param {object} params
   * @return {Promise<any>}
   */
  async getPreviousProfile(params) {
    params = params || {}
    const url = new URL(window.location.origin + '/api/profiles/previous')
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value)
    }
    const response = await fetch(url.toString())

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * check if a profile is available
   * @param {object} params
   * @return {Promise<any>}
   */
  async profileIsAvailable(params) {
    params = params || {}
    const url = new URL(window.location.origin + '/api/profiles/available/one')
    
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value)
    }
    const response = await fetch(url.toString())

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * check if some profiles are available
   * @param {object} params
   * @return {Promise<any>}
   */
  async getAvailableProfiles(params) {
    params = params || {}
    const url = new URL(window.location.origin + '/api/profiles/available')

    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value)
    }
    const response = await fetch(url.toString())

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * find my profiles
   * @return {Promise<any>}
   */
  async findMyProfiles() {
    const url = new URL(window.location.origin + '/api/profiles/mines')
    const response = await fetch(url.toString())
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * user has no profile
   * @param {int} giidId
   * @return {Promise<any>}
   */
  async answerNoProfile(giidId){
    const response = await fetch(window.location.origin + '/api/company_notified/nothing-to-propose',{
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({giidId}),
      method: 'POST'
    })

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()

  }
}
const profileApiClient = new ProfileApiClient()
export default profileApiClient

