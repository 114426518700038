import React from 'react'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import session from '../../lib/session'

class Login extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func, // withTranslation
      value: PropTypes.object,
      onChange: PropTypes.func.isRequired
    }
  }

  async componentDidMount() {
    const user = await session.currentUser(true)
    if (user) {
      this.props.onChange(user)
    }
  }

  get className() {
    return 'login'
  }

  _login() {
    session.login()
  }

  render() {
    return (
      <div className={this.className}>
        <div className={`${this.className}__header`}>
          <img src={'/assets/images/logo.jpg'}/>
        </div>
        <p dangerouslySetInnerHTML={{__html: this.props.t('login.welcome')}}/>
        <button className={`${this.className}__submit`}
          onClick={() => this._login()}>
          {this.props.t('action.login')}
        </button>
      </div>
    )
  }
}

export default withTranslation()(Login)
