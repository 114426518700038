import React from 'react'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import NeedApiClient from "../../service/need.api.client";
import ProposalApiClient from "../../service/proposal.api.client";
import ProfileApiClient from "../../service/profile.api.client";
import PropTypes from "prop-types";
import ProfileDetails from "../profile/profile.details";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight, faUser, faChevronLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export class ProposalList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      need: undefined,
      sortedProfiles: [],
      selectedProfile: undefined,
      error: false
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func,
      need: PropTypes.object
    }
  }

  get className() {
    return 'proposal-list'
  }

  async componentDidMount() {
    try {
      const requestId = parseInt(window.location.pathname.split('/').slice(-1)[0])
      const {need} = await NeedApiClient.findOne(requestId)
      const {proposals} = await ProposalApiClient.findBy({need: requestId})
      const {profiles} = await ProfileApiClient.findMyProfiles()
      this.setState({need, proposals, profiles, sortedProfiles: await this._sortProfiles(need, profiles)})
    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('profile.error.load')})
    }
  }

  /**
   * Sort profiles
   * @param {object} need
   * @param {object[]} profiles
   * @return {Promise<*>}
   * @private
   */
  async _sortProfiles(need, profiles) {
    const profilesMatch = profiles?.filter(profile => {
      if (profile.abilities.some(ability => ability.id === need?.job.type.id)) {
        return profile
      }
    }).map(profile => profile.id)
    try {
      const {availableProfiles} = await ProfileApiClient.getAvailableProfiles({
        profilesId: profilesMatch,
        idTaskForce: need.id
      })
      return availableProfiles.map(profile => {
        return {
          ...profile,
          matchIndicator: Number.parseFloat((profile.skills.filter(s => need.skills.includes(s)).length)
              / ((need.skills.length || 0)), 2)
        }
      }).sort((a, b) => b.matchIndicator - a.matchIndicator)
    } catch (err) {
      console.error(err)
      this.setState({error: this.props.t('profile.error.load')})
    }
  }

  async _selectProfile(profile) {
    await this.setState({selectedProfile: profile})
  }

  render() {
    if (!this.state.need) {
      if(parseInt(window.location.pathname.split('/').slice(-1)[0])) {
        return (<div className={`${this.className}__loading`}>
          <FontAwesomeIcon icon={faSpinner}/>
        </div>)
      }
      return null
    }
    if (this.state.selectedProfile) {
      return (
        <ProfileDetails
          profile={this.state.selectedProfile}
          need={this.state.need}
          onGoBack={() => this.setState({selectedProfile: undefined})}
        />
      )
    }
    return (
      <>
        {this.state.error ?
          <div className={`${this.className}__error`}>{this.state.error}</div> :
          this.state.sortedProfiles.length ?
            <div className={`${this.className}`}>
              <div className={`${this.className}__head`}>
                <div className={`${this.className}__title`}>
                  {this.props.t('proposal.list.title')}
                </div>
                <div className={`${this.className}__need`}>
                  <div className={`${this.className}__need__icon`}>
                    <FontAwesomeIcon icon={faUser}/>
                  </div>
                  <div className={`${this.className}__need-resource`}>
                    {this.state.need.resource}
                  </div>
                </div>
                <div className={`${this.className}__demand`}>
                  {this.props.t('proposal.list.demand')}
                </div>
              </div>
              <div className={`${this.className}__name`}>
                {this.state.sortedProfiles.map(profile => {
                  return (
                    <button
                      key={profile.id}
                      className={profile.matchIndicator >= 0.50
                        ? `${this.className}__name__list skills__success`
                        : profile.matchIndicator >= 0.25
                          ? `${this.className}__name__list skills__medium`
                          : `${this.className}__name__list skills__none`}
                      onClick={() => this._selectProfile(profile)}>
                      {profile.label}
                      <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                  )
                })}
              </div>
            </div>:
            <div className={`${this.className}__empty`}>{this.props.t('proposal.list.noProfiles')}</div>
        }
        <div className={`${this.className}__actions`}>
          <Link className={`${this.className}__actions__new-profile`}
            to={`/${this.state.need.id}/profile/create`}>
            {this.props.t('profile.create.label')}
          </Link>
          <Link className={`${this.className}__actions__back`}
            to={`/requestsOthers/${this.state.need.id}`}>
            <FontAwesomeIcon icon={faChevronLeft}/>
            {this.props.t('action.return')}
          </Link>
        </div>
      </>
    )
  }
}

export default withTranslation()(withRouter(ProposalList))
