class CompanyNotifiedApiClient {

  /**
   * Check if user has already answered to a Giid
   * @param {int} giidId
   * @return {Promise<any>}
   */
  async hasAlreadyAnswered(giidId) {
    const response = await fetch(window.location.origin + '/api/company_notified/has-already-answered/' + giidId)
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }
}

const companyNotifiedApiClient = new CompanyNotifiedApiClient()
export default companyNotifiedApiClient
