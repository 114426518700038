import React from 'react'
import PropTypes from 'prop-types'

export default class IconReset extends React.Component {

  static get propTypes() {
    return {
      className: PropTypes.string
    }
  }

  render() {
    return <svg
      className={this.props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66.16 75.62">
      <g>
        <path d="M4.59,18.91l3.26,49.93c.24,3.87,3.34,6.78,7.08,6.78H51.23c3.75,0,6.84-2.91,7.08-6.78l3.13-49.93H4.59Zm29.09,47.4c-4.01,0-7.82-1.28-11.02-3.68-1.16-.87-1.4-2.52-.53-3.68,.88-1.16,2.52-1.4,3.68-.53,2.29,1.72,5.01,2.63,7.88,2.63,7.23,0,13.12-5.89,13.12-13.12s-5.88-13.13-13.12-13.13c-4.13,0-8.02,1.98-10.48,5.24h5.21c1.45,0,2.62,1.18,2.62,2.63s-1.17,2.63-2.62,2.63h-11.83c-1.45,0-2.63-1.18-2.63-2.63v-11.83c0-1.45,1.18-2.62,2.63-2.62s2.63,1.17,2.63,2.62v5.77c3.46-4.4,8.71-7.07,14.46-7.07,10.13,0,18.39,8.25,18.39,18.39s-8.26,18.38-18.39,18.38ZM61.44,4.73h-14.18l-1.06-2.11c-.8-1.6-2.44-2.62-4.23-2.62H24.19c-1.79,0-3.43,1.02-4.22,2.62l-1.07,2.11H4.72C2.12,4.73,0,6.85,0,9.46s2.12,4.72,4.72,4.72H61.44c2.61,0,4.72-2.11,4.72-4.72s-2.11-4.73-4.72-4.73Z"
          fill="currentColor"/>
      </g>
    </svg>
  }
}
