import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Route, Navigate, Routes, Link} from "react-router-dom";
import NeedsCreateJob from "./job";
import NeedsCreateSkills from "./skills";
import NeedsCreateDates from "./dates";
import NeedsCreateNumber from "./number";
import NeedsCreateGiid from "./giid";
import NeedsCreateComment from "./comment";
import NeedsCreateEntry from './entry'
import withRouter from '../../../lib/withRouter'
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NeedApiClient from "../../../service/need.api.client";
import IconPenToSquare from "../../../helper/penToSquare.icon";
import giidApiClient from "../../../service/giid.api.client";

export class NeedsCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentGiid: {},
      status: undefined,
      loadingPublish: false
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      params: PropTypes.object.isRequired, // withRouter
      location: PropTypes.object.isRequired, // withRouter
      navigate: PropTypes.func // withRouter
    }
  }

  get className() {
    return 'need-create'
  }

  get steps() {
    return {
      job: NeedsCreateJob,
      skills: NeedsCreateSkills,
      dates: this.props.params.id === 'undefined' && NeedsCreateDates,
      entry: NeedsCreateEntry,
      number: NeedsCreateNumber,
      giid: this.props.params.id === 'undefined' && NeedsCreateGiid,
      comment: NeedsCreateComment
    }
  }

  async componentDidMount() {
    this.setState({loading: true})
    if(this.props.params.id !== 'undefined') {
      await giidApiClient.findById(this.props.params.id).then(({giid}) => {
        this.setState({giid})
      }).catch(err => console.error(err))
    }
    this.setState({loading: false})
    this._dispatch()
  }

  async _change(key, value) {
    await this.setState({[key]: value})
    this._dispatch()
  }

  _back(key) {
    const index = Object.keys(this.steps).filter(key => this.steps[key]).findIndex(step => step === key)
    if(index > 0) {
      this.props.navigate(Object.keys(this.steps).filter(key => this.steps[key])[index -1])
      return
    }
    this.props.params.id === 'undefined' ? this.props.navigate(`/giid/mines`) :
      this.props.navigate(`/needs/mines/${this.props.params.id}`)
  }

  _dispatch() {
    for (const key of Object.keys(this.steps).filter(key => this.steps[key])) {
      if (this.state[key] === undefined) {
        this.props.navigate(key)
        return
      }
    }
    this._save()
  }

  _save() {
    this.setState({loading: true})
    const params = {}
    for (const key of Object.keys(this.steps)) {
      params[key] = this.state[key]
    }
    params.dates = params.dates ?? this.state.dates
    params.giid = params.giid ?? this.state.giid
    const maintenances = []
    params.skills.map(skill => {
      if(skill >= 50) {
        maintenances.push(skill - 50)
      }
      return skill
    })
    params.maintenances = maintenances.sort((a, b)=> a < b).join('/')

    NeedApiClient.create({...params, skills: params.skills.filter(skill => skill < 50)})
      .then(async ({needs}) => {
        const totalNeedForThisGiid = await NeedApiClient.findBy({giid: needs[0].giid.id})
        const {giid} = await giidApiClient.findById(needs[0].giid.id)
        this.setState({
          needs,
          loading: false,
          totalNeedForThisGiid: totalNeedForThisGiid?.total || 0,
          currentGiid: giid
        })
      })
      .catch(e => {
        console.error(e)
        if (e.status === 401) {
          this.context.logout()
          return
        }
        this.setState(prevState => {
          prevState.errors = prevState.errors || []
          prevState.errors.push(this.props.t('needs.create.errors.create'))
          prevState.loading = false
          return prevState
        })
      })
  }

  render() {
    const hash = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length -1]
    if(!Object.keys(this.steps).includes(hash)) {
      return <Navigate to={Object.keys(this.steps)[0]}/>
    }
    if (this.state.loading) {
      return (<div className={`${this.className}__loading`}>
        <FontAwesomeIcon icon={faSpinner}/>
      </div>)
    }
    if (this.state.errors) {
      return <div className={this.className}>
        <div className={`${this.className}__errors`}>
          {this.state.errors.map((error, key) => (
            <div className={`${this.className}__error`} key={key}>
              {error}
            </div>)
          )}
        </div>
      </div>
    }
    if (this.state.needs) {
      return <div className={`${this.className}__confirm`}>
        <h3 className={`${this.className}__success`}>
          {(this.props.params.id !== 'undefined' || !this.state.number) ? this.props.t('needs.create.success', {count: this.state.number}) :
            this.props.t('giid.created', {count: this.state.number})}
        </h3>

        <Link className={`${this.className}__continue`} to='/giid/mines'>
          {this.props.t('action.backToList')}
        </Link>
      </div>
    }
    return (
      <div className={this.className}>
        <div className={`${this.className}__title`}>
          {this.props.t('needs.create.title', {currentStep: Object.keys(this.steps).filter(key => this.steps[key]).findIndex(step => step === hash) + 1,
            totalStep: Object.keys(this.steps).filter(key => this.steps[key]).length ?? 1})}
        </div>
        <div className={`${this.className}__summary`}>
          {Object.entries(this.steps).filter(([, value]) => value).map(([key, value]) =>
            this.state[key] && value.Label && (
              <div className={`${this.className}__summary__${key}`} key={key}>
                <div className={`${this.className}__summary__value`}>
                  <value.Label value={this.state[key]}/>
                </div>
                <button className={`${this.className}__summary__edit`} onClick={()=> this.props.navigate(key)}>
                  <IconPenToSquare/>
                </button>
              </div>
            ))}
        </div>
        <div className={`${this.className}__step`}>
          <Routes>
            {Object.entries(this.steps).filter(([, value]) => value).map(([key, value]) => (
              key === 'giid' ?
                <Route
                  key={key}
                  path={key}
                  element={<value.Step
                    period={this.state['dates']}
                    value={this.state[key]}
                    onContinue={v => this._change(key, v)}
                    onBack={() => this._back(key)}/>}
                />
                :
                <Route
                  key={key}
                  path={key}
                  element={<value.Step
                    value={this.state[key]}
                    job={this.state.job}
                    onContinue={v => this._change(key, v)}
                    onBack={() => this._back(key)}/>}
                />
            ))}
          </Routes>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(NeedsCreate))
