import React from 'react'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faSpinner, faTimes} from '@fortawesome/free-solid-svg-icons'
import ProposalApiClient from '../../service/proposal.api.client'

export class OfferDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waiting: false,
      declining: false
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func,
      offer: PropTypes.object.isRequired,
      giid: PropTypes.object,
      onGoBack: PropTypes.func,
      onDecline: PropTypes.func,
      onAccept: PropTypes.func
    }
  }

  get className() {
    return 'offer-details'
  }

  get declineReason() {
    return this.props.t('offers.declineReason', {returnObjects: true})
  }

  /**
   * classify proposal's skills
   * @param {number[]} skills
   * @private
   */
  _skillsClassifier(skills) {
    const s = {}
    skills.map(skill=> {
      if(skill < 12 || skill === 31) {
        s.caces ? s.caces.push(this.props.t(`proposal.skill.${skill}`)) : s.caces = [this.props.t(`proposal.skill.${skill}`)]
        return
      }
      if(skill < 17) {
        s.meca ? s.meca.push(this.props.t(`proposal.skill.${skill}`)) : s.meca = [this.props.t(`proposal.skill.${skill}`)]
        return
      }
      if(skill < 21 || skill === 28) {
        s.wms ? s.wms.push(this.props.t(`proposal.skill.${skill}`)) : s.wms = [this.props.t(`proposal.skill.${skill}`)]
        return
      }
      if(skill < 28) {
        s.domain ? s.domain.push(this.props.t(`proposal.skill.${skill}`)) : s.domain = [this.props.t(`proposal.skill.${skill}`)]
        return
      }
      s.others ? s.others.push(this.props.t(`proposal.skill.${skill}`)): s.others = [this.props.t(`proposal.skill.${skill}`)]
    })
    return s
  }

  /**
   * Decline a proposal
   * @param {string} reason
   * @return {Promise<void>}
   * @private
   */
  async _decline(reason) {
    this.setState({ waiting: true })
    await ProposalApiClient.decline({
      needId: this.props.offer.idNeed,
      serviceId: this.props.offer.idService,
      reason,
      proposalAuthorId: parseInt(this.props.offer.contact),
      giidId: this.props.giid.id
    }).then(() => this.props.onDecline(this.props.offer)
    ).catch(() => this.setState({error: this.props.t('offers.error.decline')}))

  }

  /**
   * Accept a proposal
   * @return {Promise<void>}
   * @private
   */
  async _accept() {
    this.setState({ waiting: true })
    await ProposalApiClient.accept({
      giidId: this.props.giid.id,
      needId: this.props.offer.idNeed,
      companyId: this.props.offer.company.id,
      serviceId: this.props.offer.idService,
      proposalAuthorId: parseInt(this.props.offer.contact)
    }).then(() => this.props.onAccept(this.props.offer)
    ).catch(() => this.setState({error: this.props.t('offers.error.accept')}))

  }

  __declineReason() {
    return <div className={`${this.className}__expand`}>
      <FontAwesomeIcon icon={faTimes} onClick={() => this.setState({declining: false})}/>
      <div className={`${this.className}__expand__wrapper`}>
        {Object.values(this.declineReason).map((reason, key) =>
          <button className={`${this.className}__expand__item`}
            onClick={() => this._decline(reason)}
            key={key}>
            {reason}
          </button>
        )}
      </div>
    </div>
  }

  render() {
    if (!this.props.offer) {
      return null
    }
    return(
      <div className={this.className}>
        <div className={`${this.className}__info`}>
          <div className={`${this.className}__info__name`}>{this.props.t('proposal.list.item.label', {name: this.props.offer.label})} </div>
          <div className={`${this.className}__info__company`}> {this.props.t('proposal.list.item.company', {company: this.props.offer.company.name})}</div>
          {Object.entries(this._skillsClassifier(this.props.offer.skills)).filter(entry=> entry[1].length > 0).map(([key, value], i) =>
            <div className={`${this.className}__info__skills`} key={i}>{this.props.t(`proposal.skill.label.${key}`)} :{' '}
              {value.map(v=> v.replace(new RegExp(this.props.t(`proposal.skill.label.${key}`) + '[ ]*'), '')).join(' / ')}</div>
          )}
        </div>
        <div className={`${this.className}__actions`}>
          <div className={`${this.className}__action__back`} onClick={()=> this.props.onGoBack()}>
            <FontAwesomeIcon icon={faChevronLeft}/> {this.props.t('proposal.action.backToList')}
          </div>
          {this.state.error && <div className={`${this.className}__error`}>{this.state.error}</div>}
          {this.state.waiting ?
            <div className={`${this.className}__waiting`}>
              <FontAwesomeIcon icon={faSpinner}/>  {this.props.t('proposal.waiting')}
            </div>
            :
            <div className={`${this.className}__actions_`}>
              {this.state.declining ?
                this.__declineReason() :
                <button className={`${this.className}__action__decline`} onClick={() => this.setState({declining: true})}>
                  {this.props.t('proposal.action.decline')}
                </button>
              }
              {this.props.offer.isSelected ?
                <div className={`${this.className}__already-accepted`}>
                  {this.props.t('proposal.accepted')}
                </div>
                :
                <button className={`${this.className}__action__accept`} onClick={() => this._accept()}>
                  {this.props.t('proposal.action.accept')}
                </button>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(OfferDetails))
