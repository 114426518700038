import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner, faTimes} from '@fortawesome/free-solid-svg-icons'

let index = 0

export default class FormAutocomplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      opened: false
    }
    this.index = index++
    this.ref = React.createRef()
    this._click = this._click.bind(this)
  }

  static get propTypes() {
    return {
      className: PropTypes.string,
      emptyMessage: PropTypes.string,
      loading: PropTypes.bool,
      onChange: PropTypes.func,
      onChangeSearch: PropTypes.func,
      opened: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            label: PropTypes.oneOfType([
              PropTypes.arrayOf(PropTypes.node),
              PropTypes.node,
              PropTypes.string
            ]).isRequired
          }),
          PropTypes.string
        ])),
      value: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
          ]),
          value: PropTypes.object
        }),
        PropTypes.string
      ]),
      searchValue: PropTypes.string
    }
  }

  componentDidMount() {
    document.addEventListener('click', this._click)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._click)
  }

  _click(event) {
    if (this.state.opened && !this.ref.current.contains(event.target)) {
      this.setState({opened: false})
      return
    }
    if (!this.state.opened && this.ref.current.contains(event.target)) {
      this.setState({opened: true})
    }
  }

  _select(option) {
    this.setState({opened: false})
    if (this.props.onChange) {
      this.props.onChange(option)
    }
  }

  _unselect() {
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  __option(option) {
    return (<div key={option.key}
      className={`${this.className}__autocomplete__option ${option.clickable !== false && 'clickable'}`}
      onClick={() => option.clickable !== false && this._select(option)}>
      <div className={`${this.className}__autocomplete__option__label`}>
        {option.label}
      </div>
      {option.children && <div className={`${this.className}__children`}>
        {option.children.map((option, key) => this.__option({key, ...option}))}
      </div>}
    </div>
    )
  }

  get className() {
    return this.props.className ?? 'form-autocomplete'
  }

  render() {
    const {
      className,
      emptyMessage,
      loading,
      onChangeSearch,
      options,
      value,
      searchValue,
      ...props
    } = this.props
    const mainClassName = className ?? 'form-autocomplete'
    const classNames = [mainClassName,
      loading && 'loading',
      value !== undefined && 'disabled'
    ].filter(v => v)
    return (
      <div className={classNames.join(' ')} ref={this.ref}>
        <div className={`${mainClassName}__control`}>
          <input
            autoComplete='off'
            id={`${mainClassName}__${this.index}`}
            {...props}
            onChange={(e) => {
              if (onChangeSearch) {
                onChangeSearch(e.target.value)
              }
              this.setState({
                search: e.target.value,
                opened: true
              })
            }}
            value={value ? (value.label ?? value) : (searchValue !== undefined ? searchValue : this.state.search)}
            disabled={value !== undefined}
          />
          {value !== undefined && (
            <div className={`${mainClassName}__control__right`} onClick={() => this._unselect()}>
              <FontAwesomeIcon icon={faTimes}/>
            </div>)}
          {loading && (
            <div className={`${mainClassName}__loading`}>
              <FontAwesomeIcon icon={faSpinner}/>
            </div>)}
        </div>
        {(this.state.opened || this.props.opened) && !!options?.length && (
          <div className={`${mainClassName}__autocomplete`}>
            {options?.length ? options.map((option, key) => this.__option({key, ...option})) : (
              emptyMessage && (
                <div className={`${mainClassName}__autocomplete__emptyMessage`}>
                  {emptyMessage}
                </div>
              ))}
          </div>
        )}
      </div>
    )
  }
}
