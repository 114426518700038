import React from 'react'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import withRouter from '../lib/withRouter'
import NeedApiClient from "../service/need.api.client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import session from "../lib/session";
import Login from "./block/login";
import GiidApiClient from "../service/giid.api.client";
import IconReset from "../helper/reset.icon";
import appClient from "../service/app.client";
import {NATIONAL_ADMIN, PREPROD, TEST, DEMO} from "../helper/constants";
import {Link} from "react-router-dom";

const tempo = 5000  // 0.5 second

export class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      user: undefined,
      regionName: '',
      totalRegions: 0,
      totalNational: 0,
      totalMines: 0,
      totalOthers: 0,
      noUser: undefined,
      login: false
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func, // withTranslation
      navigate: PropTypes.func
    }
  }

  get className() {
    return 'home'
  }

  _navigate(destination) {
    this.props.navigate(destination)
  }

  async _logout() {
    await session.logout()
    this.setState({login: true, noUser: true})
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    try {
      const { totalRegions, totalNational, regionName } = await GiidApiClient.count(user.organisation)
      const needsDataMines = await NeedApiClient.findMines(user.group?.company?.id, 1, {onlyCount: true})
      const needsDataOthers = await NeedApiClient.findOthers(user.group?.company?.id, 1, {onlyCount: true})
      const totalNeedsRegion = await NeedApiClient.findOthers(user.group?.company?.id, 1, {onlyCount: true, organisation: user.organisation})

      this.setState({
        user,
        totalRegions,
        totalNational,
        regionName,
        totalMines: needsDataMines.total,
        totalOthersRegion: needsDataOthers.total,
        totalOthers: needsDataOthers.total,
        totalNeedsRegion: totalNeedsRegion.total,
        noUser: !user
      })
    }
    catch (e) {
      console.error(e)
      this.setState({user, noUser: true})
    }
  }

  async _reset() {
    try {
      await appClient.reset()
      this.setState({success: this.props.t('home.reset.success')})
      if (this.reset) {
        clearTimeout(this.reset)
      }
      this.reset = setTimeout( async () => {
        await this._logout()
      }, tempo)

    } catch (e) {
      console.error(e)
      this.setState({error: this.props.t('home.reset.error')})
    }
  }

  render() {
    if(this.state.noUser === true) {
      return (
        this.state.login === true ?
          <Login onChange={(user) => this.setState({user})}/>
          :
          <div className={`${this.className}__action-reconnect`} onClick={()=> this._logout()}>{this.props.t('action.reconnect')}</div>
      )
    }

    return (
      this.state.user ? (
        <>
          <div className={this.className}>
            <div className={`${this.className}__header`}>
              <img src={'/assets/images/logo.jpg'}/>
            </div>
            <div className={`${this.className}__hello`}>
              {this.props.t('home.hello')} {this.state.user.identity?.usualName.toLowerCase()
                .split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')} !
            </div>
            <div className={`${this.className}__welcome`}>
              <div className={`${this.className}__welcome__main`}>
                {this.props.t('home.welcome.main')}
              </div>
              {this.state.user.type !== NATIONAL_ADMIN &&
              <p className={`${this.className}__welcome__mines`}>
                {this.state.totalRegions > 0 ? this.props.t('home.welcome.mine', {
                  count: this.state.totalNeedsRegion,
                  region: this.state.regionName,
                  giid: this.state.totalRegions
                }) :
                  this.props.t('home.welcome.mine_zero', {region: this.state.regionName})}
              </p>
              }
              <p className={`${this.className}__welcome__others`}>
                {this.state.totalNational > 0 ? this.props.t('home.welcome.others', {count: this.state.totalNeedsRegion, giid: this.state.totalNational})
                  : this.props.t('home.welcome.others_zero')}
              </p>
            </div>

            <Link className={`${this.className}__mines`} to='/giid/mines'>
              <img src={'/assets/images/IDSHARE-APP-MOBILE-LOGO-DEMANDER.svg'}/>
              <div className={`${this.className}__mines__text`}>
                <div className={`${this.className}__mines__text__title`}> {this.props.t('home.request.mines.title')}</div>
                {this.state.totalMines > 0 ? this.props.t('home.request.mines.count', {count: this.state.totalMines})
                  : this.props.t('home.request.mines.count_zero')}
              </div>
              <FontAwesomeIcon icon={faChevronRight}/>
            </Link>

            <Link className={`${this.className}__others`} to='/giid/others'>
              <img src={'/assets/images/IDSHARE-APP-MOBILE-LOGO-PROPOSER.svg'}/>
              <div className={`${this.className}__others__text`}>
                <div> {this.props.t('home.request.others.title')}</div>
                {this.state.totalOthers > 0 ? this.props.t('home.request.others.count', {count: this.state.totalOthers}) :
                  this.props.t('home.request.others.count_zero')}
              </div>
              <FontAwesomeIcon icon={faChevronRight}/>
            </Link>
            {this.state.user.type === NATIONAL_ADMIN &&
            (window.location.origin.includes(PREPROD) || window.location.origin.includes(TEST) || window.location.origin.includes(DEMO)) &&
              <button className={`${this.className}__reset`} onClick={() => this._reset()}>
                <IconReset/>
                {this.props.t('home.reset.label')}
              </button>
            }
            {this.state.error &&
              <div className={`${this.className}__error`}>{this.state.error}</div>
            }
            {this.state.success &&
            <div className={`${this.className}__success`}>{this.state.success}</div>
            }
          </div>
        </>
      )
        :
        <FontAwesomeIcon className={`${this.className}__loading`} icon={faSpinner}/>
    )
  }
}


export default withTranslation()(withRouter(Home))
