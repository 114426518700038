import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class _Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ?? 1
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.number,
      onContinue: PropTypes.func.isRequired,
      onBack: PropTypes.func.isRequired
    }
  }

  get className() {
    return 'need-create-number'
  }

  _change(value) {
    value = value < 1 ? 1 : value
    this.setState({value})
  }

  render() {
    return <div className={`${this.className}`}>
      <div className={`${this.className}__label`}>
        {this.props.t('needs.create.number.label')}
      </div>
      <div className={`${this.className}__value`}>
        <div className={`${this.className}__input`}>
          <FontAwesomeIcon icon={faMinus} onClick={() => this._change(this.state.value - 1)}/>
          <input type="number" step={1} inputMode="numeric" min={1} value={this.state.value}
            onChange={(e) => this._change(e.target.value)}/>
          <FontAwesomeIcon icon={faPlus} onClick={() => this._change(this.state.value + 1)}/>
        </div>
      </div>
      <div className={`${this.className}__actions`}>
        <button className={`${this.className}__action`} onClick={() => this.props.onBack()}>
          {this.props.t('action.previous')}
        </button>
        <button className={`${this.className}__action`} onClick={() => this.props.onContinue(this.state.value)}>
          {this.props.t('action.next')}
        </button>
      </div>
    </div>


  }
}

class _Label extends React.Component {

  static get propTypes() {
    return {
      t: PropTypes.func.isRequired, // withTranslation
      value: PropTypes.number.isRequired
    }
  }

  render() {
    return <> {this.props.t('needs.create.summary.number', {number: this.props.value})}</>
  }
}

export default {
  Step: withTranslation()(_Step),
  Label: withTranslation()(_Label)
}
