import React from 'react'
import {withTranslation} from 'react-i18next'
import withRouter from '../../lib/withRouter'
import PropTypes from 'prop-types'
import NeedApiClient from '../../service/need.api.client'
import ProposalApiClient from '../../service/proposal.api.client'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBell, faChevronLeft, faTrash} from '@fortawesome/free-solid-svg-icons'
import RequestInfo from './request.info'
import GiidApiClient from '../../service/giid.api.client'
import {Link} from 'react-router-dom'
import 'moment/locale/fr'

export class Request extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      need: undefined,
      proposals: [],
      confirmDeleteNeed: false,
      confirmDeleteGiid: false,
      messages: []
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func,
      navigate: PropTypes.func
    }
  }

  get className() {
    return 'request'
  }

  async componentDidMount() {
    const requestId = parseInt(window.location.pathname.split('/').slice(-1)[0])
    const {need} = await NeedApiClient.findOne(requestId)
    const {proposals} = await ProposalApiClient.findBy({need: requestId})
    this.setState({need, proposals})
  }

  _navigate(destination) {
    this.props.navigate(destination)
  }

  async _deleteNeed() {
    try {
      const deletedNeed = await NeedApiClient.delete(this.state.need.id)
      if (deletedNeed.need.status === 0) {
        const currentGiid = await GiidApiClient.findById(deletedNeed.need.giid.id)
        if(!currentGiid.needs.length){
          this.setState({confirmDeleteNeed: false, confirmDeleteGiid: true, giidId:deletedNeed.need.giid.id})
        }
        else {
          this._navigate('/giid/mines')
        }
      }
    } catch (e) {
      console.error(e)
      this.setState({confirmDeleteNeed: false})
    }
  }

  _closeGiid(){
    GiidApiClient.close(this.state.giidId)
    this._navigate('/giid/mines')
  }

  _keepGiid(){
    this._navigate('/giid/mines')
  }


  render() {
    if (!this.state.need) {
      return null
    }
    if (this.state.confirmDeleteNeed) {
      return (
        <div className={this.className}>
          <div className={`${this.className}__confirm`}>
            <div className={`${this.className}__confirm__label`}>
              {this.props.t('needs.request.delete.title')}
            </div>
            <button className={`${this.className}__action__confirm-delete`}
              onClick={()=> this._deleteNeed()}>
              {this.props.t('needs.request.delete.confirm')}
              <FontAwesomeIcon className={`${this.className}__action__delete`} icon={faTrash}/>
            </button>

            <button className={`${this.className}__action__cancel-delete`}
              onClick={()=>this.setState({confirmDeleteNeed : false})}>
              {this.props.t('needs.request.delete.cancel')}
            </button>
          </div>
        </div>
      )
    }

    if (this.state.confirmDeleteGiid) {
      return (
        <div className={`${this.className}`}>
          <div className={`${this.className}__confirm`}>
            <div className={`${this.className}__confirm__label-giid`}>
              {this.props.t('needs.request.deleteGiid.title')}
            </div>
            <button className={`${this.className}__action__confirm-delete__giid`}
              onClick={()=> this._closeGiid()}>
              {this.props.t('needs.request.deleteGiid.confirm')}
              <FontAwesomeIcon className={`${this.className}__action__delete`} icon={faTrash}/>
            </button>
            <button className={`${this.className}__action__cancel-delete__giid`}
              onClick={()=> this._keepGiid()}>
              {this.props.t('needs.request.deleteGiid.cancel')}
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className={`${this.className}`}>
        <RequestInfo
          need={this.state.need}
          scope='mine'
        />
        <div className={`${this.className}__actions`}>
          {this.state.proposals?.length
            ? <Link className={`${this.className}__action__mines`}
              to={`/offers/list/${this.state.need.id}`}>
              {this.state.proposals.length > 1 ?
                this.props.t('needs.request.action.showProposals', {count: this.state.proposals.length})
                : this.props.t('needs.request.action.showProposal')}
              <FontAwesomeIcon className={`${this.className}__action__response`} icon={faBell}/>
            </Link>
            :
            <div className={`${this.className}__action__mines__disabled`}>
              {this.props.t('needs.request.action.noProposals')}
            </div>
          }
          <button className={`${this.className}__action__mines`} onClick={() => this.setState({confirmDeleteNeed: true})}>
            {this.props.t('needs.request.action.delete')}
            <FontAwesomeIcon className={`${this.className}__action__delete`} icon={faTrash}/>
          </button>
          <button className={`${this.className}__action__mines back`} onClick={() => this._navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft}/>
            {this.props.t('action.return')}
          </button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(Request))
