import React from "react";
import withRouter from '../../lib/withRouter'
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faSnowplow, faUser} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import moment from "moment";


export class RequestInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      need: undefined,
      proposals: []
    }
  }

  static get propTypes() {
    return {
      t: PropTypes.func,
      need : PropTypes.object,
      scope : PropTypes.string
    }
  }

  get className() {
    return 'request-info'
  }

  get skills() {
    return [...(this.props.need.skills ?? []), ...(this.props.need.maintenances?.split('/') ?? []).map(item => (parseInt(item) || 0) + 50)]
  }

  _summaryBuilder(result) {
    let res = ''
    const filter = ['name', 'phone', 'period']
    Object.keys(result).filter(key=> key && result[key] && !filter.some(k => key.startsWith(k))).map(key => {
      if(key.startsWith('from')) {
        res += `De ${this.props.t(`needs.create.entry.schedule.${result[key]}`)} `
        return
      }
      if(key.startsWith('to')) {
        res += `à ${this.props.t(`needs.create.entry.schedule.${result[key]}`)} : `
        return
      }
      res += `${this.props.t(`needs.create.entry.summary.days.${key.slice(0, key.length -1)}`)} `
    })
    return res
  }

  render() {
    return (
      <div className={this.className}>
        <div className={`${this.className}__label`}>
          {this.props.scope === 'mines'
            ? this.props.t('needs.request.title.mine')
            : this.props.t('needs.request.title.other', {site: this.props.need?.giid?.site})
          }
        </div>
        <div className={`${this.className}__resource`}>
          <FontAwesomeIcon icon={faUser}/>
          {this.props.need.resource}</div>
        <div className={`${this.className}__skills`}>
          <FontAwesomeIcon icon={faSnowplow}/>
          {this.skills.length > 0 ?
            <>{this.props.t('needs.skill.label')}
              {this.skills.map(s => this.props.t(`needs.skill.${s}`)
                .replace(new RegExp(this.props.t('needs.skill.label')
                      + '[ ]*'), '')).join(' / ')}
            </>
            :
            <>{this.props.t('needs.skill.without')}</>
          }
        </div>
        <div className={`${this.className}__dates`}>
          <FontAwesomeIcon icon={faCalendarAlt}/>
          {this.props.t('needs.create.summary.dates', {
            startDate: moment(this.props.need.startDate * 1000).format('DD-MM-YYYY'),
            endDate: moment(this.props.need.endDate * 1000).format('DD-MM-YYYY')
          })}
        </div>
        <div className={`${this.className}__entry`}>
          <FontAwesomeIcon icon={faCalendarAlt}/>
          {this.props.need.giid?.entry && this._summaryBuilder(this.props.need.giid?.entry) !== '' ?
            this._summaryBuilder(this.props.need.giid?.entry) : this.props.t('needs.create.entry.none')
          }
        </div>
        {this.props.need.info &&
              <div className={`${this.className}__comment`}>
                <div className={`${this.className}__comment__label`}>{this.props.t('needs.request.info.label')}</div>
                <div className={`${this.className}__comment__feed`}>{this.props.need.info}</div>
              </div>
        }
      </div>
    )
  }
}

export default withTranslation()(withRouter(RequestInfo))
