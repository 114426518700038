class NeedApiClient {

  /**
   * create a need
   * @param {object} params
   * @return {Promise<any>}
   */
  async create(params) {
    return fetch(window.location.origin + '/api/needs', {
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(params),
      method: 'POST'
    }).then(function (response) {
      return response.json()
    })
  }

  /**
   * delete a need
   * @param {int} id
   * @return {Promise<any>}
   */
  async delete(id) {
    const response = await fetch(window.location.origin + '/api/needs/' + id, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'DELETE'
    })

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * find my needs
   * @param {int} userId
   * @param {int=} page
   * @param {object} filter
   * @return {Promise<any>}
   */
  async findMines(userId, page, filter) {
    const params = {
      author: userId,
      statusNeed : 1,
      endOfTeamBuilding: !!filter.onlyCount,
      ...filter
    }
    return await this.findBy(params, page || 1)
  }

  /**
   * find other needs
   * @param {int} userId
   * @param {int=} page
   * @param {object} filter
   * @return {Promise<any>}
   */
  async findOthers(userId, page, filter) {
    const params = {
      author: "^" + userId,
      endOfTeamBuilding: true,
      statusNeed : 1,
      ...filter
    }
    return await this.findBy(params, page ||1)
  }

  /**
   * find needs by params
   * @param {object} params
   * @param {int=} page
   * @return {Promise<any>}
   */
  async findBy(params, page) {
    params = params || {}
    const url = new URL(window.location.origin + '/api/needs')
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value)
    }
    if(page){
      url.searchParams.set('page', page)
    }
    const response = await fetch(url.toString())

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * find a need
   * @param {int} needId
   * @return {Promise<any>}
   */
  async findOne(needId) {
    const response = await fetch(window.location.origin + '/api/needs/' + needId)
    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }

  /**
   * get needs stats
   * @return {Promise<boolean>}
   */
  async findOthersStats() {
    await fetch(window.location.origin + '/api/needs?limit')
    return true
  }

  /**
   * find available profiles for a need
   * @param {string} needId
   * @return {Promise<any>}
   */
  async getNeedProfilesAvailable(needId) {
    const response = await fetch(`${window.location.origin}/api/needs/availableProfiles/${needId}`)

    if (!response.ok) {
      throw await response.json()
    }
    return await response.json()
  }
}

const needApiClient = new NeedApiClient()
export default needApiClient

